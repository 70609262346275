import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Dimensions,
} from "react-native";
import { RadioButton, Button } from "react-native-paper";

import moment from "moment";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";
import IconRefresh from "react-native-vector-icons/FontAwesome";
import DataTable from "react-data-table-component";
import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";

export default function ViewZoneProfile({ navigation, route }) {
  const { ZoneId } = route?.params;
  // console.log("ZONE ID VIEW", ZoneId);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [empCode, setEmpCode] = useState("");
  const [projectId, setProjectId] = useState("");

  const [ZoneID, setZoneID] = useState("");
  const [isZoneIDValid, setZoneIDValid] = useState(false);
  const [isZoneIDEntered, setZoneIDEntered] = useState(false);

  const [ZoneName, setZoneName] = useState("");
  const [isZoneNameValid, setZoneNameValid] = useState(true);
  const [isZoneNameEntered, setZoneNameEntered] = useState(false);

  const [Lat, setLatitude] = useState("");
  const [Long, setLongitude] = useState("");

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(false);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [ZoneCode, setZoneCode] = useState("");
  const [isZoneCodeValid, setZoneCodeValid] = useState(true);
  const [isZoneCodeEntered, setZoneCodeEntered] = useState(false);

  const [Location, setLocation] = useState("");
  const [isLocationValid, setLocationValid] = useState(false);
  const [isLocationEntered, setLocationEntered] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [cityValId, setCityValId] = useState("");
  const [tahasilValue, setTahasilValue] = useState("");
  const [tahasilValId, setTahasilValId] = useState("");
  const [VillageValue, setVillageValue] = useState("");
  const [VillageValId, setVillageValId] = useState("");
  const [KhasaraValue, setKhasaraValue] = useState("");
  const [KhasaraValId, setKhasaraValId] = useState("");

  //Dropdown value pickup
  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegaDetails, setOmegaDetails] = useState([]);

  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [shouldShow, setShouldShow] = useState(true);
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [OmegaError, setOmegaError] = useState("");
  //Error message
  const [ZoneIdError, setZoneIdError] = useState("");

  const [ZoneNameError, setZoneNameError] = useState("");
  const [ZoneCodeError, setZoneCodeError] = useState("");
  const [ZoneLocationError, setZoneLocationError] = useState("");

  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var alpha_pattern = new RegExp(/^[a-zA-Z0-9]*$/);

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;
  const dropdownRefOmegaiD = useRef();
  const closeMenu = () => {
    dropdownRefOmegaiD.current.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  const DigitalOutputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={OmegaDetails}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const AnalogInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnsAnalog}
                  data={AnalogInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
  const DigitalInputRoute = () => (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#F6F9F9",
      }}
    >
      {" "}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e3e0e0",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columnDigitalInput}
                  data={DigitalInput}
                  customStyles={customStyles}
                  pagination
                />
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );

  const renderScene = SceneMap({
    DigitalOutput: DigitalOutputRoute,
    DigitalInput: DigitalInputRoute,
    AnalogInput: AnalogInputRoute,
  });
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },
    { key: "DigitalInput", title: "Digital Input" },
    { key: "AnalogInput", title: "Analog Input" },
  ]);

  useFocusEffect(
    React.useCallback(() => {
      getZoneById();
      getAdminData();
    }, [])
  );
  //Get details when click on omega id
  const getOmegaDetails = async (deviceId) => {
    // console.log("device_id", deviceId);
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("OmegaDetails", OmegaDetails);
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        // console.log("Omega details api====>", OmegaDetailsResponse.data);
        // setShouldShow(false)
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].digitalInput);
        setDigitalInput(OmegaDetailsResponse.data[0].analogInput);
      } else {
        setIsLoading(false);
        //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Get Zone  data by id
  const getZoneById = async () => {
    setIsLoading(true);
    try {
      var ZoneData = JSON.stringify({
        module: "zone_master",
        relation: [
          {
            module: "zone_devices",
            subModule: "devices_master",
          },
        ],
        id: ZoneId,
      });
      const ZoneDataById = await postData(ZoneData, "/masters/getMasterList");
      setIsLoading(false);
      var code = ZoneDataById.code;
      if (code == 1) {
        // console.log("Zone data list api====>", ZoneDataById.data);

        setZoneName(ZoneDataById.data[0].zone_name);
        setZoneCode(ZoneDataById.data[0].zone_code);
        setStatusChecked(ZoneDataById.data[0].active);
        getOmegaDetails(ZoneDataById.data[0].zone_devices[0].device_id);

        setOmegaIdValue(
          ZoneDataById.data[0].zone_devices[0].devices_master.device_name
        );
        setLatitude(ZoneDataById.data[0].zone_devices[0].devices_master.lat);
        setLongitude(ZoneDataById.data[0].zone_devices[0].devices_master.long);
      } else {
        setIsLoading(false);
        //alert(ZoneDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      // setloginId(userDetailsresponse.email_id);
      setProjectId(userDetailsresponse.project_selected);
      setEmpCode(userDetailsresponse.employee_code);
    }
  };

  // DATATABLE subzone TYPE COLUMN AND STYLE
  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  const columnsAnalog = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const columnDigitalInput = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  return (
    <View style={styles.container}>
      <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: "10%" } : { flexDirection: "row", width: "100%", marginTop: "5%" }}>
        <View style={{ flex: 4, margin: 10 }}>
          <Text style={styles.lableText}>
            Profile {">"}{" "}
            <Text onPress={() => navigation.navigate("ZoneProfile")}>
              {" "}
              Zone/Group{" "}
            </Text>{" "}
            {">"} View Zone/Group
          </Text>
        </View>
        {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
      </View>
      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={[styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 45,
              paddingHorizontal: 25,
            },
          ]}
        >
          <View
            style={isMobile ? {
              // flex: 1,
              justifyContent: "center",
              alignItems: "center",
              // flexDirection: "row",
            } : {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View style={isMobile ? { marginLeft: "0%", marginBottom: "0%" } : { flex: 1, marginLeft: "10%", marginBottom: "0%" }}>
              <View
                style={{
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                {/* <Text style={styles.lableText}>Zone Id</Text>
                <TextInput
                  style={styles.input}
                  placeholder="Enter Zone id"
                // value={}


                />
                <Text style={[styles.error_text]}>{ZoneIdError}</Text> */}
                <View style={isMobile ? { marginLeft: -95, marginTop: -45 } : null}>
                  <Text style={styles.lableText}>Zone Name</Text>
                  <Text style={styles.input}>{ZoneName}</Text>
                </View>


                {/* <View> */}
                {/* </View> */}
                <View style={isMobile ? {marginLeft:-95, marginTop:-5} : null}>
                  <Text style={styles.lableText}>Omega name</Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "100%" }}>
                      <Text style={[styles.input]}>{OmegaIdValue}</Text>
                    </View>
                  </View>
                  {/* <Text style={styles.lableText}>Status </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[styles.statusNames, { color: "#05D6B3" }]}>
                      Active
                    </Text>
                    <RadioButton
                      value={isstatuschecked}
                      status={isstatuschecked === 1 ? "checked" : "unchecked"}
                      onPress={() => setStatusChecked(1)}
                    />
                    <Text style={[styles.statusNames, { color: "#E11A1A" }]}>
                      InActive
                    </Text>
                    <RadioButton
                      value={isstatuschecked}
                      status={isstatuschecked === 0 ? "checked" : "unchecked"}
                      onPress={() => setStatusChecked(0)}
                    />{" "}
                  </View> */}
                </View>
              </View>
            </View>
            <View
              style={isMobile ? {
                // flex: 1,
                marginBottom: "0%",
              } : {
                flex: 1,
                marginBottom: "0%",
              }}
            >
              <View>
              <View style={isMobile ? {marginLeft:-100} : null}>
              <Text style={styles.lableText}>Location </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.inputLocation}>{Lat}</Text>
                  <Text style={styles.inputLocation}>{Long}</Text>
                </View>
              </View>
              <View style={isMobile ? {marginLeft:-100} : null}>
              <Text style={[styles.error_text]}>{ZoneCodeError}</Text>
                <Text style={styles.lableText}>Zone Code</Text>

                <Text style={[styles.input]}>{ZoneCode}</Text>
              </View>
                
              </View>
            </View>
          </View>

          <View style={isMobile ? { marginTop: 20, width: "105%", alignSelf: "center", marginBottom:10 } : { marginTop: 20, width: "40%", alignSelf: "center" }}>
            <TabView
              navigationState={{ index, routes }}
              renderScene={renderScene}
              onIndexChange={setIndex}
              initialLayout={{ width: layout.width }}
              // renderLabel={({ route, color }) => (
              //   <Text style={{ color: 'black', margin: 8 }}>
              //     {route.title}
              //   </Text>
              // )}
              renderTabBar={(props) => (
                <TabBar
                  {...props}
                  indicatorStyle={isMobile ? { backgroundColor: "#2c7bbe", height: 70 } : { backgroundColor: "#2c7bbe", height: 50 }}
                  renderLabel={({ route, color }) => (
                    <Text style={{ color: "Black", margin: 8 }}>
                      {route.title}
                    </Text>
                  )}
                  style={{ backgroundColor: "#F6F9F9" }}
                />
              )}

            // <-- add this line
            />
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
            }}
          >
            <TouchableOpacity
              style={isMobile ? {
                alignItems: "center",
                backgroundColor: "#006EB9",
                padding: 10,
                borderWidth: 1,
                borderColor: "#006EB9",
                width: "35%",
                marginRight: 10,
              } : styles.saveBtn}
              onPress={() => navigation.navigate("ZoneProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>

          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  inputLocation: {
    // borderColor: "#c3c3c3",
    width: "40%",
    // borderWidth: 1,
    // borderRadius: 4,
    marginRight: 5,
    padding: 0,
    marginTop: 4,
    marginBottom: 6,
  },
  input: {
    // borderColor: "#c3c3c3",
    width: "70%",
    // borderWidth: 1,
    // borderRadius: 4,

    padding: 0,
    marginTop: 4,
    marginBottom: 10,

    // fontSize: 16,
    // color: "#484848",
    // fontWeight: "700",
    // marginTop: 18,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    fontSize: 16,
    color: "#484848",
    fontWeight: "700",
    // marginTop: 18,
  },
});
