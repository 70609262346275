import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  Alert,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Pressable,
} from "react-native";
import { RadioButton, Button } from "react-native-paper";
import moment from "moment";
import { TabView, SceneMap } from "react-native-tab-view";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";
import { DataTable as DataTableNEW } from "react-native-paper";
import { Text, View } from "../../../components/Themed";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import IconEdit from "react-native-vector-icons/FontAwesome";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import SuccessPopup from "../../../components/SuccessPopup";

export default function EditFarmerProfile({ navigation, route }) {
  const { farmerId } = route?.params;

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  // const [empCode, setEmpCode] = useState("U00001");

  const [FarmerID, setFarmerID] = useState("");
  const [isFarmerIDValid, setFarmerIDValid] = useState(false);
  const [isFarmerIDEntered, setFarmerIDEntered] = useState(false);

  const [FarmerName, setFarmerName] = useState("");
  const [isFarmerNameValid, setFarmerNameValid] = useState(true);
  const [isFarmerNameEntered, setFarmerNameEntered] = useState(false);

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(true);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [MobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setMobileNumberValid] = useState(true);
  const [isMobileNumberEntered, setMobileNumberEntered] = useState(false);

  const [OmegavalueId, setOmegaValueiD] = useState("");

  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegaIdValid, setOmegaIdValid] = useState(false);
  const [OmegaIdEntered, setOmegaIdEntered] = useState(false);

  const [CountryNameData, setCountryNameData] = useState([]);
  const [isCountryNameDataValid, setCountryNameDataValid] = useState(true);
  const [isCountryNameDataEntered, setCountryNameDataEntered] = useState(false);

  const [StateNameData, setStateNameData] = useState([]);
  const [isStateNameDataValid, setStateNameDataValid] = useState(true);
  const [isStateNameDataEntered, setStateNameDataEntered] = useState(false);

  const [CityNameData, setCityNameData] = useState([]);
  const [isCityNameDataValid, setCityNameDataValid] = useState(true);
  const [isCityNameDataEntered, setCityNameDataEntered] = useState(false);

  const [TahasilNameData, setTahasilNameData] = useState([]);
  const [isTahasilNameDataValid, setTahasilNameDataValid] = useState(true);
  const [isTahasilNameDataEntered, setTahasilNameDataEntered] = useState(false);

  const [VillageNameData, setVillageNameData] = useState([]);
  const [isVillageNameDataValid, setVillageNameDataValid] = useState(true);
  const [isVillageNameDataEntered, setVillageNameDataEntered] = useState(false);

  const [PinCode, setPinCode] = useState("");
  const [isPinCodeValid, setPinCodeValid] = useState(false);
  const [isPinCodeEntered, setPinCodeEntered] = useState(false);

  const [Address, setAddress] = useState("");
  const [isAddressValid, setAddressValid] = useState(false);
  const [isAddressEntered, setAddressEntered] = useState(false);

  const [KhasaraNumber, setKhasaraNum] = useState("");
  const [isKhasaraValid, setKhasaraValid] = useState(true);
  const [isKhasaraEntered, setKhasaraEntered] = useState(false);

  const [loginId, setloginId] = useState("");
  const [isloginIdValid, setloginIdValid] = useState(false);
  const [isloginIdEntered, setloginIdEntered] = useState(false);

  const [Password, setPassword] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [isPasswordEntered, setPasswordEntered] = useState(false);
  const [projectId, setProjectId] = useState("");

  const [MaritialStatus, setMaritialStatus] = useState("");
  const [isMaritialStatusValid, setMaritialStatusValid] = useState(false);
  const [isMaritialStatusEntered, setMaritialStatusEntered] = useState(false);

  const [Status, setStatus] = useState("");
  const [isStatusValid, setStatusValid] = useState(false);
  const [isStatusEntered, setStatusEntered] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState();
  const [countryValId, setCountryValId] = useState();
  const [stateValue, setStateValue] = useState();
  const [stateValId, setStateValId] = useState();
  const [cityValue, setCityValue] = useState();
  const [cityValId, setCityValId] = useState();
  const [tahasilValue, setTahasilValue] = useState();
  const [tahasilValId, setTahasilValId] = useState();
  const [VillageValue, setVillageValue] = useState();
  const [VillageValId, setVillageValId] = useState();

  const [OmegaDetails, setOmegaDetails] = useState([]);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);

  const [shouldShow, setShouldShow] = useState(false);

  //Dropdown value pickup
  const [OmegaIdValue, setOmegaIdValue] = useState(null);

  const [ValveName, setValveName] = useState(null);
  const [ValveIndex, setValveIndex] = useState(null);

  const [ValveId, setValveID] = useState("");
  //Error message
  const [FarmerNameError, setFarmerNameError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [mobileNumError, setMobileNumError] = useState("");
  const [countryError, seTCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [tahasilError, setTahasilError] = useState("");
  const [villageError, setVillageError] = useState("");
  const [OmegaError, setOmegaError] = useState("");
  const [ValveError, setValveError] = useState("");

  const [pinError, setPinError] = useState("");
  const [khasaraError, setkhasaraError] = useState("");

  const [fieldname, setfieldname] = useState("");
  const [Cultivated, setCultivated] = useState(0);
  const [Water, setWater] = useState(0);
  const [Crops, setCrops] = useState(0);
  const [address, setaddress] = useState("");
  const [zone, setzone] = useState("");
  const [subZone, setSubZone] = useState("");
  const [devices, setDevice] = useState("");

  const [tableData, settableData] = useState([]);
  const [ProjectIdOmega, setProjectIdOmega] = useState("");
  const [CultivatedTotal, setCultivatedTotal] = useState(0);
  const [WaterTotal, setWaterTotal] = useState(0);
  const [cropsTotal, setcropsTotal] = useState(0);

  const [deviceRelationship, setdeviceRelationship] = useState("");
  const [deviceRelationshipId, setdeviceRelationshipId] = useState();
  // console.log("tableDatatableData", tableData);

  const updateTableData = (dataindex) => {
    settableData([
      ...tableData,
      {
        // id: 1,
        data: {
          farmer_id: FarmerID,
          field_id: dataindex,
          khasara_number: fieldname,
          cultivated_area: Cultivated,
          water_demand: Water,
          crops: Crops,
          address: address,
          zone: zone,
          subZone: subZone,
          devices: devices,
          OmegaIdValue: OmegaIdValue,
          device_id: OmegavalueId,
          ValveName: ValveName,
          valve_index: ValveIndex,
          valve_id: ValveId,
          device_relationship: deviceRelationship,
          device_relationship_referance_id: deviceRelationshipId,
        },
      },
    ]);

    setfieldname(""), setCultivated(0);
    setOmegaIdValue(null), setOmegaValueiD("");
    setValveName(null);
    setValveIndex(null);
    setValveID("");
    setWater(0);
    setCrops(0);
    setaddress("");
    setzone("");
    setSubZone(""), setDevice("");
  };

  // console.log("tableDatatableData update", tableData);

  const [qualification_Error, setQualificationError] = useState("");
  const [ActiveTab, setActiveTab] = useState(0);

  //Dropdown

  const dropdownRefcountry = useRef();
  const dropdownRefState = useRef();
  const dropdownRefCity = useRef();
  const dropdownRefTahasil = useRef();
  const dropdownRefVillage = useRef();
  const dropdownRefOmegaiD = useRef();

  const closeMenu = () => {
    dropdownRefcountry.current.close();
    dropdownRefState.current.close();
    dropdownRefCity.current.close();
    dropdownRefTahasil.current.close();
    dropdownRefVillage.current.close();
    dropdownRefOmegaiD.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);
  const radioButtonsData = [
    {
      id: "1",
      label: "Male",
      value: 1,
      color: "red",
      selected: true,
    },
    {
      id: "2",
      label: "Female",
      value: 2,
      color: "red",
      selected: false,
    },
  ];

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  //DOb
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  //DOJ
  const [joinDate, setJoinDate] = React.useState<Date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  const onDismissJoin = React.useCallback(() => {
    setOpen(false);
  }, [setJoinOpen]);

  const onConfirmJoin = React.useCallback(
    (params) => {
      setJoinOpen(false);
      setJoinDate(params.date);
    },
    [setJoinOpen, setJoinDate]
  );
  //Regex
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );
  var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;
  const FirstRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#ff4081" }}>
      {" "}
      <Text>tab1 </Text>
    </View>
  );

  const SecondRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#673ab7" }}>
      <Text>tab2 </Text>
    </View>
  );
  const ThirdRoute = () => (
    <View style={{ flex: 1, backgroundColor: "#eee" }}>
      <Text>tab2 </Text>
    </View>
  );

  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
    third: ThirdRoute,
  });
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "first", title: "First" },
    { key: "second", title: "Second" },
    { key: "third", title: "Third" },
  ]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      getFarmerById();
      // getOmegaIdList();
      getCountry();
    }, [])
  );

  useEffect(() => {
    var cultiTotal = 0;
    var waterTotal = 0;
    var cropsTotal = 0;
    for (let i = 0; i < tableData.length; i++) {
      cultiTotal = cultiTotal + Number(tableData[i].data.cultivated_area);
      waterTotal = waterTotal + Number(tableData[i].data.water_demand);
      cropsTotal = cropsTotal + Number(tableData[i].data.crops);
    }
    setCultivatedTotal(cultiTotal);
    setWaterTotal(waterTotal);
    setcropsTotal(cropsTotal);
  }, [tableData]);

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in subzone create", projValue);
      setProjectId(projValue);
      getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setloginId(userDetailsresponse.email_id);
      setProjectId(userDetailsresponse.project_selected);

      getOmegaDependentByProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
    }
  };
  //Get FARMER  data by id
  const getFarmerById = async () => {
    setIsLoading(true);
    try {
      var FarmerData = JSON.stringify({
        id: farmerId,
        module: "farmer_master",
      });
      const FarmerDataById = await postData(
        FarmerData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = FarmerDataById.code;

      // console.log("FarmerDataById", FarmerDataById.data);

      if (code == 1) {

        settableData([]);

        getFarmerdataById(FarmerDataById.data[0].id);
        setFarmerID(FarmerDataById.data[0].farmer_id);
        // setFarmerUniqueId(FarmerDataById.data[0].id);
        setFarmerName(FarmerDataById.data[0].farmer_name);
        setMobileNumber(FarmerDataById.data[0].mobile_number);
        setEmailId(FarmerDataById.data[0].email_id);
        setOmegaIdValue(FarmerDataById.data[0].omega_id);
        setCountryValue(FarmerDataById.data[0].country_id);
        setCountryValId(FarmerDataById.data[0].country_id);
        setStateValue(FarmerDataById.data[0].state_id);
        setStateValId(FarmerDataById.data[0].state_id);
        setCityValue(FarmerDataById.data[0].city_id);
        setCityValId(FarmerDataById.data[0].city_id);
        setTahasilValue(FarmerDataById.data[0].tahasil_id);
        setTahasilValId(FarmerDataById.data[0].tahasil_id);
        setVillageValue(FarmerDataById.data[0].village_id);
        setVillageValId(FarmerDataById.data[0].village_id);
        setKhasaraNum(FarmerDataById.data[0].khasara_number);

        setPinCode(FarmerDataById.data[0].pincode);
        if (FarmerDataById.data[0].pincode) {
          setPinCodeValid(true);
        }
        setAddress(FarmerDataById.data[0].address);

        setPassword(FarmerDataById.data[0].password);
        getDependentCountryMaster(FarmerDataById.data[0].country_id);
        getDependentStateMaster(FarmerDataById.data[0].state_id);
        getDependentCityMaster(FarmerDataById.data[0].city_id);
        getDependentTahasilMaster(FarmerDataById.data[0].tahasil_id);

        getFarmerdataById(FarmerDataById.data[0].id);
      } else {
        setIsLoading(false);
        // //alert(FarmerDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const deletefarmerrecord = async (id) => {
    // console.log("ididid", id);
    setIsLoading(true);
    try {
      var FarmerData = JSON.stringify({
        id: id,
        module: "farmer_details",
      });
      const FarmerDataById = await postData(
        FarmerData,
        "/masters/deleteRecordById"
      );
      setIsLoading(false);
      var code = FarmerDataById.code;
      // console.log("codecode", code);
      if (code == 1) {
        // console.log("record deleted");
        getFarmerById();
      } else {
        setIsLoading(false);
        //alert(FarmerDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getsubzone = async (id) => {
    setIsLoading(true);
    try {
      var FarmerData = JSON.stringify({
        module: "sub_zone_master",
        id: id,
      });
      const FarmerDataById = await postData(
        FarmerData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = FarmerDataById.code;

      if (code == 1) {
        // console.log("Farmer zone====>", FarmerDataById.data.sub_zone_name);
        setSubZone(FarmerDataById.data.sub_zone_name);
      } else {
        setIsLoading(false);
        // //alert(FarmerDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getFarmerdataById = async (id) => {
    // console.log("farmerID", id);
    setIsLoading(true);
    try {
      var FarmerData = JSON.stringify({
        farmer_id: id,
      });
      const FarmerDataById = await postData(
        FarmerData,
        "masters/getFarmerDetails"
      );
      setIsLoading(false);
      var code = FarmerDataById.code;

      // console.log("FarmernewDataById", FarmerDataById.data);

      if (code == 1) {
        // console.log("Farmer details ====>", FarmerDataById.data);
        var finaldata = [];
        var cultivate = 0;
        var water = 0;
        var crops = 0;

        FarmerDataById.data.map((Fdata) => {
          var data = [];
          return (
            (data = {
              farmer_id: Fdata.farmer_id ? Fdata.farmer_id : "",
              field_id: Fdata.field_id ? Fdata.field_id : "",
              khasara_number: Fdata.khasara_number ? Fdata.khasara_number : "",
              cultivated_area: Fdata.cultivated_area
                ? Fdata.cultivated_area
                : "",
              water_demand: Fdata.water_demand ? Fdata.water_demand : "",
              crops: Fdata.crops ? Fdata.crops : "",
              address: Fdata.address ? Fdata.address : "",
              zone: Fdata.zone_name ? Fdata.zone_name : "",
              subZone: Fdata.sub_zone_name ? Fdata.sub_zone_name : "",
              devices: Fdata.profile_name ? Fdata.profile_name : "",
              OmegaIdValue: Fdata.device_name ? Fdata.device_name : "",
              device_id: Fdata.device_id ? Fdata.device_id : "",
              ValveName: Fdata.valve_name ? Fdata.valve_name : "",
              valve_index: Fdata.valve_index ? Fdata.valve_index : "",
              valve_id: Fdata.valve_id ? Fdata.valve_id : "",
              device_relationship: Fdata.device_relationship
                ? Fdata.device_relationship
                : "",
              device_relationship_referance_id:
                Fdata.device_relationship_referance_id
                  ? Fdata.device_relationship_referance_id
                  : "",
            }),
            finaldata.push({ id: Fdata.id, data: data }),
            (cultivate = cultivate + Number(Fdata.cultivated_area)),
            (water = water + Number(Fdata.water_demand)),
            (crops = crops + Number(Fdata.crops)),
            getOmegaDetails(Fdata.device_id)
          );
        });
        setCultivatedTotal(cultivate),
          setWaterTotal(water),
          setcropsTotal(crops),
          settableData(finaldata);
      } else if (code == 0) {
        var finaldata = [];
        var data = [];
        data = {
          farmer_id: farmerId,
          field_id: "F0101",
          khasara_number: "",
          cultivated_area: "",
          water_demand: "",
          crops: "",
          address: "",
          zone: "",
          subZone: "",
          devices: "",
          OmegaIdValue: "",
          device_id: "",
          ValveName: "",
          valve_index: "",
          valve_id: "",
          device_relationship: "",
          device_relationship_referance_id: "",
        };
        finaldata.push({ id: 0, data: data });
        settableData(finaldata);
      } else {
        setIsLoading(false);
        // //alert(FarmerDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          active: 1,
        },
        relation: [],
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = countryNameResponse.code;
      if (code == 1) {
        // console.log("Country list====>", countryNameResponse.data);
        // setCountryLength(countryNameResponse.data.length);
        setCountryNameData(countryNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderValveIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
        {item.id === ValveId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderCountryList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.country}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.country}</Text>}
        {item.id === countryValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const getOmegaDetails = async (deviceId) => {
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("OmegaDetails payload", OmegaDetails);
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {

        // // console.log(" Farmer details api valves====>", OmegaDetailsResponse.data[0].valves);

        setShouldShow(false);
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
      } else {
        setIsLoading(false);
        //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getZoneSubZone = async (deviceId) => {
    setIsLoading(true);
    setzone("");
    setSubZone("");
    setDevice("");
    setdeviceRelationship("");
    setdeviceRelationshipId();
    // console.log("deviceIddeviceId", deviceId);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("OmegaDetails", OmegaDetails);
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getZoneSubZone"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        // console.log("farmer zone details api====>", OmegaDetailsResponse.data);
        if (OmegaDetailsResponse.data[0].type == 1) {
          setDevice(OmegaDetailsResponse.data[0].name);
          setdeviceRelationship(OmegaDetailsResponse.data[0].type);
          setdeviceRelationshipId(OmegaDetailsResponse.data[0].id);
        }
        if (OmegaDetailsResponse.data[0].type == 2) {
          setzone(OmegaDetailsResponse.data[0].name);
          setdeviceRelationship(OmegaDetailsResponse.data[0].type);
          setdeviceRelationshipId(OmegaDetailsResponse.data[0].id);
        }
        if (OmegaDetailsResponse.data[0].type == 3) {
          setSubZone(OmegaDetailsResponse.data[0].name);
          setdeviceRelationship(OmegaDetailsResponse.data[0].type);
          setdeviceRelationshipId(OmegaDetailsResponse.data[0].id);
        }
      } else {
        setIsLoading(false);
        //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const renderStateListItems = (item) => {
    // // console.log("State id item", item.id);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.state}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.state}</Text>}
        {item.id === stateValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCityListItems = (item) => {
    // console.log("city item name-", item.city);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.city}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.city}</Text>}
        {item.id === cityValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderTahasilListItems = (item) => {
    // console.log("tahasil item name-", item.tahasil);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {item.id === tahasilValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderVillageListItems = (item) => {
    // console.log("village item name-", item.tahasil);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {item.id === VillageValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    setFarmerNameEntered(true);
    setMobileNumberEntered(true);

    setEmailIdEntered(true);
    setPasswordEntered(true);
    setPinCodeEntered(true);
    setKhasaraEntered(true);
    // console.log("check nenter data");
    console.log(
      "isFarmerNameValid isMobileNumberValid isEmailIdValid  isPasswordValid && isPinCodeValid && isKhasaraValid",
      isFarmerNameValid,
      isMobileNumberValid,
      isEmailIdValid,
      isPasswordValid,
      isPinCodeValid,
      isKhasaraValid
    );
    //setOmegaId(OmegaIds);
    if (
      isFarmerNameValid &&
      isMobileNumberValid &&
      isEmailIdValid &&
      isPasswordValid &&
      isPinCodeValid &&
      isKhasaraValid
    ) {
      // console.log("success");
      updateFarmerProfileData();
      setFarmerNameError("");
      setMobileNumError("");
      setEmailIdError("");
      setPasswordError("");
      setPinError("");
      setkhasaraError("");
    } else {
      // console.log("error ELSE");
      if (FarmerName == "") {
        setFarmerNameError("Please enter first name");
      }
      //email id
      if (EmailId == "") {
        setEmailIdError("Please enter email id");
      }
      //Password
      if (Password == "") {
        setPasswordError("Please enter password ");
      }

      //mobile number
      if (MobileNumber == "") {
        setMobileNumError("Please enter mobile number");
      }
      //omega id
      if (OmegavalueId == "") {
        setOmegaError("Please select Omega Type");
      }

      //Country
      if (countryValue == "") {
        seTCountryError("Please select Country");
      }

      //State
      if (stateValue == "") {
        setStateError("Please select State");
      }

      //City
      if (cityValue == "") {
        setCityError("Please select City");
      }

      //Tahasil
      if (tahasilValue == "") {
        setTahasilError("Please select Tahasil");
      }

      //Village
      if (VillageValue == "") {
        setVillageError("Please select Village");
      }

      //Pin Validation
      if (PinCode == "") {
        setPinError("Please enter pin ");
      }
      //Khasara number
      if (KhasaraNumber == "") {
        setkhasaraError("Please enter Khasara ");
      }
    }
  };
  const getOmegaDependentByProjectId = async (project_id) => {
    // console.log("project_idproject_id", project_id);

    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: project_id,
        module: "devices_master",
      });
      // console.log("getdependent omega id payload", OmegaIdData);
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getDependentMasterList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Bermad edit farmer Omega list api====>", OmegaIdList.data);

        setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // const getOmegaIdList = async () => {
  //     setIsLoading(true);
  //     try {
  //         var OmegaIdData = JSON.stringify({
  //             module: "devices_master",
  //             "relation": []

  //         });
  //         const OmegaIdList = await postData(
  //             OmegaIdData,
  //             "masters/getMasterList"
  //         );
  //         setIsLoading(false);
  //         var code = OmegaIdList.code;
  //         if (code == 1) {
  //             // console.log("OMEGA type list api====>", OmegaIdList.data);

  //             setOmegaIdData(OmegaIdList.data);
  //         } else {
  //             setIsLoading(false);
  //             //alert(OmegaIdList.message);
  //         }
  //     } catch (error) {
  //         setIsLoading(false);
  //         // console.log(error);
  //     }
  // };
  // Get dependent state by country
  const getDependentCountryMaster = async (country_id) => {
    // console.log("country_id masters", country_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        column: "country_id",
        value: country_id,
        module: "state_master",
      });
      const dependentCountryDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCountryDataResponse.code;
      if (code == 1) {

        setIsLoading(false);
        // setCountryLength(dependentCountryDataResponse.data.length);
        setStateNameData(dependentCountryDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(dependentCountryDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent City by state
  const getDependentStateMaster = async (state_id) => {
    // console.log("state_id masters===", state_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "state_id",
        value: state_id,
        module: "city_master",
      });
      const dependentStateDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentStateDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentStateDataResponse.data.length);
        setCityNameData(dependentStateDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentStateDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent Tahasil by City
  const getDependentCityMaster = async (city_id) => {
    // console.log("city_id masters===", city_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "city_id",
        value: city_id,
        module: "tahasil_master",
      });
      const dependentCityDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCityDataResponse.code;
      if (code == 1) {

        setTahasilNameData(dependentCityDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentCityDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent village by Tahasil
  const getDependentTahasilMaster = async (tahasil_id) => {
    // console.log("tahasil_id masters===", tahasil_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "tahasil_id",
        value: tahasil_id,
        module: "village_master",
      });
      const dependentTahasilDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentTahasilDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentTahasilDataResponse.data.length);
        setVillageNameData(dependentTahasilDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentTahasilDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Farmer Profile Add Api
  const updateFarmerProfileData = async () => {
    setIsLoading(true);
    try {
      var FarmerMasterData = JSON.stringify({
        module: "farmer_master",
        id: farmerId,
        data: {
          project_id: projectId,
          farmer_name: FarmerName,
          mobile_number: MobileNumber,
          email_id: EmailId,
          country_id: countryValId,
          state_id: stateValId,
          city_id: cityValId,
          tahasil_id: tahasilValId,
          village_id: VillageValId,
          pincode: PinCode,
          address: Address,
          username: MobileNumber,
          password: Password,
          active: 1,
        },
        // id: ProjectIdOmega,
        submodule: "farmer_details",
        submodule_field_name: "farmer_id",
        submoduledata: tableData,
        uniqueNo: {
          field_name: "farmer_id",
          transactionId: 5,
        },
      });
      // console.log("Farmer payload===", FarmerMasterData);
      const FarmerprofileUpdateResponse = await postData(
        FarmerMasterData,
        "masters/profileCreateUpdate"
      );

      setIsLoading(false);

      var code = FarmerprofileUpdateResponse.code;
      // console.log("codecode", code);

      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // setIsVisible(false);
        // console.log("farmer updated ", FarmerprofileUpdateResponse.data);
        navigation.navigate("FarmerProfile");
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  return (
    <View style={{ paddingBottom: 5 }}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <View style={styles.container}>
        <View style={{ flexDirection: "row", width: "100%", marginTop: "0%" }}>
          <View style={{ flex: 4, margin: 10 }}>
            <Text style={styles.lableText}>
              Profile {">"}{" "}
              <Text onPress={() => navigation.navigate("FarmerProfile")}>
                {" "}
                Farmer Profile{" "}
              </Text>{" "}
              {">"} Edit Farmer Profile
            </Text>
          </View>
        </View>
        <View style={[styles.card, { flexGrow: 1 }]}>
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "white",
                borderRadius: 8,
                paddingVertical: 45,
                paddingHorizontal: 25,
              },
            ]}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                // alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View style={{ flex: 1, marginRight: 20, marginBottom: 20 }}>
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 10,
                    padding: 10,
                    // margin: 7,
                  }}
                >
                  <Text style={[styles.lableText, { marginTop: 0 }]}>
                    Farmer Id
                  </Text>
                  <TextInput
                    style={[styles.input, { backgroundColor: "#DEDEDE" }]}
                    // placeholder="Enter First name"
                    editable={false}
                    value={FarmerID}
                  />

                  <Text style={[styles.lableText, { marginTop: 8 }]}>
                    Farmer Name
                  </Text>
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    value={FarmerName}
                    onChangeText={(value) => {
                      setFarmerNameValid(false);
                      setFarmerName(value);
                      if (value.length == 0) {
                        setFarmerNameError("Please enter first name");
                      } else if (!alpha_pattern.test(value)) {
                        setFarmerNameError("Please enter character only");
                      } else {
                        setFarmerNameValid(true);
                        setFarmerNameError("");
                      }
                    }}
                  />
                  {/* <View> */}
                  <Text style={[styles.error_text]}>{FarmerNameError}</Text>
                  {/* </View> */}
                  <Text style={[styles.lableText, { marginTop: 0 }]}>
                    Mobile no.
                  </Text>
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    value={MobileNumber}
                    maxLength={10}
                    keyboardType={"numeric"}
                    onChangeText={(value) => {
                      setMobileNumberValid(false);
                      setMobileNumber(value);
                      if (value.length == 0) {
                        setMobileNumError("Please enter mobile number");
                      } else if (!regex.test(value)) {
                        setMobileNumError("Please enter number only");
                      } else if (value.length > 0 && value.length <= 9) {
                        setMobileNumError("Please enter 10 digit number");
                      } else {
                        setMobileNumberValid(true);
                        setMobileNumError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{mobileNumError}</Text>

                  <Text style={styles.lableText}>Email Id</Text>
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    value={EmailId}
                    onChangeText={(value) => {
                      setEmailIdValid(false);
                      setEmailId(value.replace(/\s/g, ""));
                      if (value.length == 0) {
                        setEmailIdError("Please enter email id");
                      } else if (!Email_Id_pattern.test(value)) {
                        setEmailIdError("Please enter valid email id  ");
                      } else {
                        setEmailIdValid(true);
                        setEmailIdError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{emailIdError}</Text>
                </View>
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 10,
                    paddingHorizontal: 10,
                    // margin: 7,
                  }}
                >
                  <Text style={styles.lableText}>Login</Text>
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    value={MobileNumber}
                    editable={false}
                    onChangeText={(value) => {
                      setloginIdValid(false);

                      setloginId(value);

                      if (Email_Id_pattern.test(value)) {
                        setloginIdValid(true);

                        //   setButtonDisabled(false);
                      }
                    }}
                  />
                  <Text style={[styles.lableText, { marginTop: 8 }]}>
                    Password
                  </Text>
                  <TextInput
                    placeholder=""
                    placeholderColor="#F0F0F0"
                    style={[styles.input, { backgroundColor: "#DEDEDE" }]}
                    value={Password}
                    editable={false}
                    secureTextEntry={true}
                    onChangeText={(value) => {
                      setPasswordValid(false);
                      setPassword(value);
                      if (value.length == 0) {
                        setPasswordError("Please enter password");
                      } else {
                        setPasswordError("");
                        setPasswordValid(true);
                      }
                    }}
                  />{" "}
                  <Text style={[styles.error_text]}>{passwordError}</Text>
                  <Text style={styles.lableText}>Pin</Text>
                  <TextInput
                    style={styles.input}
                    placeholder=""
                    value={PinCode}
                    maxLength={6}
                    onChangeText={(value) => {
                      setPinCodeValid(false);
                      setPinCode(value);
                      if (value.length == 0) {
                        setPinError("Please enter pin");
                      } else if (!regex.test(value)) {
                        setPinError("Please enter number only");
                      } else if (value.length > 0 && value.length < 6) {
                        setPinError("Please enter 6 digit pin");
                      } else {
                        setPinError("");
                        setPinCodeValid(true);
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{pinError}</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  marginTop: 53,
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                <View style={{ backgroundColor: "#fff" }}>
                  <Text style={[styles.lableText, { marginTop: 0 }]}>
                    Country
                  </Text>
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryNameData}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="id"
                    placeholder={!isFocus ? "Select Country" : "...."}
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      seTCountryError("");
                      getDependentCountryMaster(item.id);

                    }}
                    renderItem={renderCountryList}
                  />
                  <Text style={[styles.error_text]}>{countryError}</Text>
                </View>
                <View style={{ backgroundColor: "#fff" }}>
                  <Text style={styles.lableText}>State</Text>
                  <Dropdown
                    ref={dropdownRefState}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={StateNameData}
                    search
                    maxHeight={300}
                    labelField="state"
                    valueField="id"
                    placeholder={!isFocus ? "Select State" : "...."}
                    searchPlaceholder="Search..."
                    value={stateValue}
                    onChange={(item) => {
                      setStateValue(item.state);
                      setStateValId(item.id);
                      setStateError("");
                      getDependentStateMaster(item.id);
                      // console.log("state create value---add", item.id);
                    }}
                    renderItem={renderStateListItems}
                  />
                  <Text style={[styles.error_text]}>{stateError}</Text>
                </View>
                <View style={{ backgroundColor: "#fff" }}>
                  <Text style={styles.lableText}>District / City</Text>
                  <Dropdown
                    style={styles.dropdown}
                    ref={dropdownRefCity}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CityNameData}
                    search
                    maxHeight={300}
                    labelField="city"
                    valueField="id"
                    placeholder={!isFocus ? "Select City" : "...."}
                    searchPlaceholder="Search..."
                    value={cityValue}
                    onChange={(item) => {
                      setCityValue(item.city);
                      setCityValId(item.id);
                      setCityError("");
                      getDependentCityMaster(item.id);
                      // console.log("city create value---add", item.id);
                    }}
                    renderItem={renderCityListItems}
                  />
                  <Text style={[styles.error_text]}>{cityError}</Text>
                </View>
                <View style={{ backgroundColor: "#fff" }}>
                  <Text style={styles.lableText}>Tahasil</Text>
                  <Dropdown
                    ref={dropdownRefTahasil}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={TahasilNameData}
                    search
                    maxHeight={300}
                    labelField="tahasil_name"
                    valueField="id"
                    placeholder={!isFocus ? "Select Tahasil" : "...."}
                    searchPlaceholder="Search..."
                    value={tahasilValue}
                    onChange={(item) => {
                      setTahasilValue(item.tahasil_name);
                      setTahasilValId(item.id);
                      setTahasilError("");
                      getDependentTahasilMaster(item.id);
                    }}
                    renderItem={renderTahasilListItems}
                  />
                  <Text style={[styles.error_text]}>{tahasilError}</Text>
                </View>
                <View style={{ backgroundColor: "#fff" }}>
                  <Text style={styles.lableText}>Village</Text>
                  <Dropdown
                    ref={dropdownRefVillage}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={VillageNameData}
                    search
                    maxHeight={300}
                    labelField="village_name"
                    valueField="id"
                    placeholder={!isFocus ? "Select Village" : "...."}
                    searchPlaceholder="Search..."
                    value={VillageValue}
                    onChange={(item) => {
                      setVillageValue(item.village_name);
                      setVillageValId(item.id);
                      setVillageError("");
                    }}
                    renderItem={renderVillageListItems}
                  />
                  <Text style={[styles.error_text]}>{villageError}</Text>
                </View>
                <Text style={styles.lableText}>Address </Text>
                <TextInput
                  multiline
                  numberOfLines={2}
                  style={[styles.input, { marginBottom: 0 }]}
                  // onChangeText={onChangeNumber}
                  // value={number}
                  placeholder=""
                  value={Address}
                  maxLength={500}
                  // keyboardType="numeric"
                  onChangeText={(value) => {
                    setAddressValid(false);

                    setAddress(value);

                    if (alpha_pattern.test(value)) {
                      setAddressValid(true);

                      //   setButtonDisabled(false);
                    }
                  }}
                />{" "}
              </View>
            </View>
          </View>
          {shouldShow ? null : (
            <>
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <Pressable
                  onPress={() => setActiveTab(0)}
                  style={{
                    backgroundColor: ActiveTab == 0 ? "#F6F9F9" : "white",
                    borderTopLeftRadius: ActiveTab == 0 ? 10 : 0,
                    borderTopRightRadius: ActiveTab == 0 ? 10 : 0,
                    paddingHorizontal: 5,
                    width: 150,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ marginVertical: 10 }}>
                    Farmer Field Details
                  </Text>
                </Pressable>
              </View>
              <View style={{ backgroundColor: "#F6F9F9" }}>
                <ScrollView horizontal>
                  <DataTableNEW
                    style={{ flex: 1, padding: 10, backgroundColor: "#F6F9F9" }}
                  >
                    <DataTableNEW.Header style={styles.tableHeader}>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Field Id
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Field name
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Cultivated area (Ha)
                        </Text>{" "}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Water demand (m³)
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Crops
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Address
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Omega Value
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.1, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Valve
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Zone
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Sub Zone
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.10, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Devices
                        </Text>
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.12, justifyContent: "center" }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          Action
                        </Text>
                      </DataTableNEW.Title>
                    </DataTableNEW.Header>

                    {tableData.map((items, index) => {
                      return (
                        <DataTableNEW.Row style={{ flex: 1 }}>
                          <DataTableNEW.Cell
                            style={{ flex: 0.08, justifyContent: "center" }}
                          >
                            {items.data.field_id}
                          </DataTableNEW.Cell>
                          <DataTableNEW.Cell
                            style={{
                              flex: 0.08,
                              alignSelf: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextInput
                              value={items.data.khasara_number}
                              style={{
                                justifyContent: "center",
                                borderColor: "black",
                                width: "99%",
                                height: 40,
                                // outlineStyle: 'none',
                                textAlign: "center",
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                              onChangeText={(text) =>
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        id: p.id,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number: text,
                                          cultivated_area:
                                            p.data.cultivated_area,
                                          water_demand: p.data.water_demand,
                                          crops: p.data.crops,
                                          address: p.data.address,
                                          zone: p.data.zone,
                                          subZone: p.data.subZone,
                                          devices: p.data.devices,
                                          OmegaIdValue: p.data.OmegaIdValue,
                                          device_id: p.data.device_id,
                                          ValveName: p.data.ValveName,
                                          valve_index: p.data.valve_index,
                                          valve_id: p.data.ValveId,
                                          device_relationship:
                                            p.data.device_relationship,
                                          device_relationship_referance_id:
                                            p.data
                                              .device_relationship_referance_id,
                                        },
                                      }
                                      : p
                                  )
                                )
                              }
                            />
                          </DataTableNEW.Cell>

                          <DataTableNEW.Cell
                            style={{ flex: 0.08, justifyContent: "center" }}
                          >
                            <TextInput
                              value={
                                items.data.cultivated_area != 0
                                  ? items.data.cultivated_area
                                  : ""
                              }
                              style={{
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                textAlign: "center",
                                width: "99%",
                                height: 40,
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                              onChangeText={(text) =>
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        id: p.id,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number:
                                            p.data.khasara_number,
                                          cultivated_area: text,
                                          water_demand: p.data.water_demand,
                                          crops: p.data.crops,
                                          address: p.data.address,
                                          zone: p.data.zone,
                                          subZone: p.data.subZone,
                                          devices: p.data.devices,
                                          OmegaIdValue: p.data.OmegaIdValue,
                                          device_id: p.data.device_id,
                                          ValveName: p.data.ValveName,
                                          valve_index: p.data.valve_index,
                                          valve_id: p.data.ValveId,
                                          device_relationship:
                                            p.data.device_relationship,
                                          device_relationship_referance_id:
                                            p.data
                                              .device_relationship_referance_id,
                                        },
                                      }
                                      : p
                                  )
                                )
                              }
                            />
                          </DataTableNEW.Cell>

                          <DataTableNEW.Cell
                            style={{ flex: 0.08, justifyContent: "center" }}
                          >
                            <TextInput
                              value={
                                items.data.water_demand != 0
                                  ? items.data.water_demand
                                  : ""
                              }
                              style={{
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                textAlign: "center",
                                width: "99%",
                                height: 40,
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                              onChangeText={(text) =>
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        id: p.id,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number:
                                            p.data.khasara_number,
                                          cultivated_area:
                                            p.data.cultivated_area,
                                          water_demand: text,
                                          crops: p.data.crops,
                                          address: p.data.address,
                                          zone: p.data.zone,
                                          subZone: p.data.subZone,
                                          devices: p.data.devices,
                                          OmegaIdValue: p.data.OmegaIdValue,
                                          device_id: p.data.device_id,
                                          ValveName: p.data.ValveName,
                                          valve_index: p.data.valve_index,
                                          valve_id: p.data.ValveId,
                                          device_relationship:
                                            p.data.device_relationship,
                                          device_relationship_referance_id:
                                            p.data
                                              .device_relationship_referance_id,
                                        },
                                      }
                                      : p
                                  )
                                )
                              }
                            />
                          </DataTableNEW.Cell>

                          <DataTableNEW.Cell
                            style={{ flex: 0.08, justifyContent: "center" }}
                          >
                            <TextInput
                              value={
                                items.data.crops != 0 ? items.data.crops : ""
                              }
                              style={{
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                textAlign: "center",
                                width: "99%",
                                height: 40,
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                              onChangeText={(text) =>
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        id: p.id,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number:
                                            p.data.khasara_number,
                                          cultivated_area:
                                            p.data.cultivated_area,
                                          water_demand: p.data.water_demand,
                                          crops: text,
                                          address: p.data.address,
                                          zone: p.data.zone,
                                          subZone: p.data.subZone,
                                          devices: p.data.devices,
                                          OmegaIdValue: p.data.OmegaIdValue,
                                          device_id: p.data.device_id,
                                          ValveName: p.data.ValveName,
                                          valve_index: p.data.valve_index,
                                          valve_id: p.data.ValveId,
                                          device_relationship:
                                            p.data.device_relationship,
                                          device_relationship_referance_id:
                                            p.data
                                              .device_relationship_referance_id,
                                        },
                                      }
                                      : p
                                  )
                                )
                              }
                            />
                          </DataTableNEW.Cell>

                          <DataTableNEW.Cell
                            style={{ flex: 0.08, justifyContent: "center" }}
                          >
                            <TextInput
                              value={items.data.address}
                              style={{
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                textAlign: "center",
                                width: "99%",
                                height: 40,
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                              onChangeText={(text) =>
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        id: p.id,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number:
                                            p.data.khasara_number,
                                          cultivated_area:
                                            p.data.cultivated_area,
                                          water_demand: p.data.water_demand,
                                          crops: p.data.crops,
                                          address: text,
                                          zone: p.data.zone,
                                          subZone: p.data.subZone,
                                          devices: p.data.devices,
                                          OmegaIdValue: p.data.OmegaIdValue,
                                          device_id: p.data.device_id,
                                          ValveName: p.data.ValveName,
                                          valve_index: p.data.valve_index,
                                          valve_id: p.data.ValveId,
                                          device_relationship:
                                            p.data.device_relationship,
                                          device_relationship_referance_id:
                                            p.data
                                              .device_relationship_referance_id,
                                        },
                                      }
                                      : p
                                  )
                                )
                              }
                            />
                          </DataTableNEW.Cell>
                          <DataTableNEW.Cell
                            style={{
                              flex: 0.4,
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Dropdown
                              ref={dropdownRefOmegaiD}
                              style={{
                                borderColor: "#c3c3c3",
                                width: "24%",
                                borderWidth: 1,
                                borderRadius: 2,
                                justifyContent: "center",
                                alignSelf: "center",
                                height: 40,
                                marginBottom: 2,
                                padding: 10,
                              }}
                              placeholderStyle={{
                                fontSize: 12,
                              }}
                              selectedTextStyle={{
                                fontSize: 12,
                              }}
                              inputSearchStyle={{
                                height: 40,
                                fontSize: 13,
                              }}
                              iconStyle={styles.iconStyle}
                              data={OmegaIdData}
                              search
                              maxHeight={300}
                              labelField="device_name"
                              valueField="device_name"
                              placeholder={!isFocus ? "Select" : "...."}
                              searchPlaceholder="Search..."
                              value={items.data.OmegaIdValue}
                              onChange={async (item) => {
                                setOmegaError("");
                                getOmegaDetails(item.id);
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number:
                                            p.data.khasara_number,
                                          cultivated_area:
                                            p.data.cultivated_area,
                                          water_demand: p.data.water_demand,
                                          crops: p.data.crops,
                                          address: p.data.address,
                                          zone: "",
                                          subZone: "",
                                          devices: "",
                                          OmegaIdValue: item.device_name,
                                          device_id: item.id,
                                          ValveName: p.data.ValveName,
                                          valve_index: p.data.valve_index,
                                          valve_id: p.data.ValveId,
                                          device_relationship: "",
                                          device_relationship_referance_id:
                                            "",
                                        },
                                      }
                                      : p
                                  )
                                );
                                try {
                                  var OmegaDetails = JSON.stringify({
                                    device_id: item.id,
                                  });
                                  // console.log("OmegaDetails", OmegaDetails);
                                  const OmegaDetailsResponse = await postData(
                                    OmegaDetails,
                                    "masters/getZoneSubZone"
                                  );
                                  setIsLoading(false);
                                  var code = OmegaDetailsResponse.code;
                                  if (code == 1) {

                                    if (
                                      OmegaDetailsResponse.data[0].type == 1
                                    ) {
                                      setDevice(
                                        OmegaDetailsResponse.data[0].name
                                      );
                                      setdeviceRelationship(
                                        OmegaDetailsResponse.data[0].type
                                      );
                                      setdeviceRelationshipId(
                                        OmegaDetailsResponse.data[0].id
                                      );
                                      settableData(
                                        tableData.map((p) =>
                                          p.data.field_id ===
                                            items.data.field_id
                                            ? {
                                              ...p,
                                              data: {
                                                farmer_id: p.data.farmer_id,
                                                field_id: p.data.field_id,
                                                khasara_number:
                                                  p.data.khasara_number,
                                                cultivated_area:
                                                  p.data.cultivated_area,
                                                water_demand:
                                                  p.data.water_demand,
                                                crops: p.data.crops,
                                                address: p.data.address,
                                                zone: "",
                                                subZone: "",
                                                devices:
                                                  OmegaDetailsResponse.data[0]
                                                    .name,
                                                OmegaIdValue:
                                                  item.device_name,
                                                device_id: item.id,
                                                ValveName: p.data.ValveName,
                                                valve_index:
                                                  p.data.valve_index,
                                                valve_id: p.data.ValveId,
                                                device_relationship:
                                                  OmegaDetailsResponse.data[0]
                                                    .type,
                                                device_relationship_referance_id:
                                                  OmegaDetailsResponse.data[0]
                                                    .id,
                                              },
                                            }
                                            : p
                                        )
                                      );
                                    }
                                    if (
                                      OmegaDetailsResponse.data[0].type == 2
                                    ) {
                                      setzone(
                                        OmegaDetailsResponse.data[0].name
                                      );
                                      setdeviceRelationship(
                                        OmegaDetailsResponse.data[0].type
                                      );
                                      setdeviceRelationshipId(
                                        OmegaDetailsResponse.data[0].id
                                      );
                                      settableData(
                                        tableData.map((p) =>
                                          p.data.field_id ===
                                            items.data.field_id
                                            ? {
                                              ...p,
                                              data: {
                                                farmer_id: p.data.farmer_id,
                                                field_id: p.data.field_id,
                                                khasara_number:
                                                  p.data.khasara_number,
                                                cultivated_area:
                                                  p.data.cultivated_area,
                                                water_demand:
                                                  p.data.water_demand,
                                                crops: p.data.crops,
                                                address: p.data.address,
                                                zone: OmegaDetailsResponse
                                                  .data[0].name,
                                                subZone: "",
                                                devices: "",
                                                OmegaIdValue:
                                                  item.device_name,
                                                device_id: item.id,
                                                ValveName: p.data.ValveName,
                                                valve_index:
                                                  p.data.valve_index,
                                                valve_id: p.data.ValveId,
                                                device_relationship:
                                                  OmegaDetailsResponse.data[0]
                                                    .type,
                                                device_relationship_referance_id:
                                                  OmegaDetailsResponse.data[0]
                                                    .id,
                                              },
                                            }
                                            : p
                                        )
                                      );
                                    }
                                    if (
                                      OmegaDetailsResponse.data[0].type == 3
                                    ) {
                                      setSubZone(
                                        OmegaDetailsResponse.data[0].name
                                      );
                                      setdeviceRelationship(
                                        OmegaDetailsResponse.data[0].type
                                      );
                                      setdeviceRelationshipId(
                                        OmegaDetailsResponse.data[0].id
                                      );
                                      settableData(
                                        tableData.map((p) =>
                                          p.data.field_id ===
                                            items.data.field_id
                                            ? {
                                              ...p,
                                              data: {
                                                farmer_id: p.data.farmer_id,
                                                field_id: p.data.field_id,
                                                khasara_number:
                                                  p.data.khasara_number,
                                                cultivated_area:
                                                  p.data.cultivated_area,
                                                water_demand:
                                                  p.data.water_demand,
                                                crops: p.data.crops,
                                                address: p.data.address,
                                                zone: "",
                                                subZone:
                                                  OmegaDetailsResponse.data[0]
                                                    .name,
                                                devices: "",
                                                OmegaIdValue:
                                                  item.device_name,
                                                device_id: item.id,
                                                ValveName: p.data.ValveName,
                                                valve_index:
                                                  p.data.valve_index,
                                                valve_id: p.data.ValveId,
                                                device_relationship:
                                                  OmegaDetailsResponse.data[0]
                                                    .type,
                                                device_relationship_referance_id:
                                                  OmegaDetailsResponse.data[0]
                                                    .id,
                                              },
                                            }
                                            : p
                                        )
                                      );
                                    }
                                  } else {
                                    setIsLoading(false);
                                    //alert(OmegaDetailsResponse.message);
                                  }
                                } catch (error) {
                                  setIsLoading(false);
                                  // console.log(error);
                                }
                              }}
                              // onChangeText={(text) => settableData(tableData.map(p =>
                              //   p.field_id === items.id
                              //     ? { ...p, address: text }
                              //     : p
                              // ))}
                              renderItem={renderOmegaIdListItems}
                            />
                            <Dropdown
                              ref={dropdownRefOmegaiD}
                              style={{
                                borderColor: "#c3c3c3",
                                width: "24%",
                                marginLeft: 5,
                                borderWidth: 1,
                                borderRadius: 2,
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                height: 40,
                                padding: 10,
                              }}
                              placeholderStyle={{
                                fontSize: 12,
                              }}
                              selectedTextStyle={{
                                fontSize: 12,
                              }}
                              inputSearchStyle={{
                                height: 40,
                                fontSize: 13,
                              }}
                              iconStyle={styles.iconStyle}
                              data={OmegaDetails}
                              search
                              maxHeight={300}
                              labelField="name"
                              valueField="name"
                              placeholder={!isFocus ? "Select" : "...."}
                              searchPlaceholder="Search..."
                              value={items.data.ValveName}
                              onChange={(item) => {
                                setValveError("");
                                settableData(
                                  tableData.map((p) =>
                                    p.data.field_id === items.data.field_id
                                      ? {
                                        ...p,
                                        id: p.id,
                                        data: {
                                          farmer_id: p.data.farmer_id,
                                          field_id: p.data.field_id,
                                          khasara_number:
                                            p.data.khasara_number,
                                          cultivated_area:
                                            p.data.cultivated_area,
                                          water_demand: p.data.water_demand,
                                          crops: p.data.crops,
                                          address: p.data.address,
                                          zone: p.data.zone,
                                          subZone: p.data.subZone,
                                          devices: p.data.devices,
                                          OmegaIdValue: p.data.OmegaIdValue,
                                          device_id: p.data.device_id,
                                          ValveName: item.name,
                                          valve_index: item.index,
                                          valve_id: item.id,
                                          device_relationship:
                                            p.data.device_relationship,
                                          device_relationship_referance_id:
                                            p.data
                                              .device_relationship_referance_id,
                                        },
                                      }
                                      : p
                                  )
                                );
                              }}
                              renderItem={renderValveIdListItems}
                            />
                            <TextInput
                              value={items.data.zone}
                              style={{
                                marginHorizontal: 1,
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                width: "16%",
                                marginLeft: 5,
                                borderRadius: 2,
                                marginTop: 2,
                                height: 40,
                                padding: 10,
                                textAlign: "center",
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                            // onChangeText={(text) => setzone(text)}
                            />
                            <TextInput
                              value={items.data.subZone}
                              style={{
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                width: "16%",
                                marginLeft: 5,
                                borderRadius: 2,
                                height: 40,
                                padding: 10,
                                textAlign: "center",
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                            // onChangeText={(text) => setSubZone(text)}
                            />
                            <TextInput
                              value={items.data.devices}
                              style={{
                                justifyContent: "center",
                                alignSelf: "flex-start",
                                borderColor: "black",
                                // outlineStyle: 'none',
                                width: "16%",
                                marginLeft: 5,
                                borderRadius: 2,
                                height: 40,
                                padding: 10,
                                textAlign: "center",
                                borderWidth: 1,
                                backgroundColor: "transparent",
                              }}
                            // onChangeText={(text) => setSubZone(text)}
                            />
                          </DataTableNEW.Cell>
                          <DataTableNEW.Cell
                            style={{ flex: 0.12, justifyContent: "center" }}
                          >
                            <TouchableOpacity onPress={() => { }}>
                              <IconEdit
                                name="pencil"
                                size={13}
                                color="#fff"
                                style={{
                                  padding: 6,
                                  backgroundColor: "#08588E",
                                  borderRadius: 12,
                                }}
                              />
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                updateTableData(
                                  tableData.length > 0
                                    ? tableData.length < 9
                                      ? `F0${tableData.length + 1}0${tableData.length + 1
                                      }`
                                      : `F${tableData.length + 1}${tableData.length + 1
                                      }`
                                    : "F0101"
                                );
                              }}
                              style={{ marginHorizontal: 2 }}
                            >
                              <IconEdit
                                name="plus"
                                size={13}
                                color="#fff"
                                style={{
                                  padding: 6,
                                  backgroundColor: "#7CA53C",
                                  borderRadius: 12,
                                }}
                              />
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => deletefarmerrecord(items.id)}
                            >
                              <IconEdit
                                name="trash-o"
                                size={14}
                                color="#fff"
                                style={{
                                  padding: 6,
                                  backgroundColor: "#FF0000",
                                  borderRadius: 12,
                                }}
                              />
                            </TouchableOpacity>
                          </DataTableNEW.Cell>
                        </DataTableNEW.Row>
                      );
                    })}

                    <DataTableNEW.Header
                      style={{ backgroundColor: "transparent" }}
                    >
                      <DataTableNEW.Title
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        Total{" "}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        Total: {tableData ? tableData.length : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        Total: {CultivatedTotal ? CultivatedTotal : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        Total: {WaterTotal ? WaterTotal : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        Total: {cropsTotal ? cropsTotal : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        Total: {tableData ? tableData.length : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.1, justifyContent: "center" }}
                      >
                        Total: {tableData ? tableData.length : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.1, justifyContent: "center" }}
                      >
                        Total: {tableData ? tableData.length : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.1, justifyContent: "center" }}
                      >
                        Total: {tableData ? tableData.length : 0}
                      </DataTableNEW.Title>
                      <DataTableNEW.Title
                        style={{ flex: 0.1, justifyContent: "center" }}
                      >
                        Total: {tableData ? tableData.length : 0}
                      </DataTableNEW.Title>
                    </DataTableNEW.Header>
                  </DataTableNEW>
                </ScrollView>
              </View>
            </>
          )}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <TouchableOpacity
              style={styles.saveBtn}
              onPress={() => CheckEnteredData()}
            >
              <Text style={styles.btnText}>Update</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.saveBtn}
              onPress={() => navigation.navigate("FarmerProfile")}
            >
              <Text style={styles.btnText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    padding: 15,
    // marginTop: 150,
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  tableHeader: {
    // backgroundColor: "#DCDCDC",
    backgroundColor: "#319ef0a3",
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",
    width: "100%",
    flex: 1,
    paddingBottom: 10,
    marginVertical: 0,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 0,
    // fontSize: 21,
  },
});
