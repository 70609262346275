import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import React, { Children, Component, useEffect, useState } from "react";
import { Tooltip, Text as TextTool } from "react-native-elements";
import IconTimer from "react-native-vector-icons/Entypo";
import AntDesign from "react-native-vector-icons/AntDesign";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  Dimensions,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
  Modal,
  TextInput,
  useWindowDimensions,
} from "react-native";
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'

import {
  RadioButton,
} from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome5";
import { useSelector } from "react-redux";
import IconCross from "react-native-vector-icons/Entypo";
import {
  MenuProvider,
  MenuContext,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { postData, baseUrlSocket } from "../../networking/api";
import moment, { isMoment } from "moment";
import SuccessPopup from "../../components/SuccessPopup";
export default function ValveView({ navigation, route }) {
  const { OmegaId, OmegaName } = route?.params;
  const [timeHr, settimeHr] = useState(0);
  const [timemin, settimemin] = useState(0);
  const [timesec, settimesec] = useState(0);
  var projectName = useSelector((state) => state.projectName);
  const [project_name, setProjectName] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [projectId, setProjectId] = useState("");
  //   const [animations, setAnimations] = useState(constructAnimations());
  const [ValveIsVisible, setValveIsVisible] = useState(false);
  const [isMeasureType, setMeasureType] = useState(2);
  const [isOnline, setIsOnline] = useState("");
  const [valveDetails, setValveDetails] = useState([]);
  const [NewvalveDetails, setNewValveDetails] = useState([]);
  const [waterMeterDetails, setWaterMeterDetails] = useState([]);
  const [analogDetails, setAnalogDetails] = useState([]);
  const [OmegaDetailsGrid, setOmegaDetailsGrid] = useState([]);
  const [valveamount, setvalveamount] = useState<Number>(0);
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [valveIndex, setValveIndex] = useState("");
  const [valveStatus, setvalveStatus] = useState("");
  const [valveName, setValveName] = useState("");
  const [valveState, setValveState] = useState("");
  const [selectedValveIndex, setSelectedValveIndex] = useState("");
  const [refreshflatlist, setrefreshflatlist] = useState(false);
  const [valveDetCount, setvalveDetCount] = useState(0);
  const [vNewIndex, setVNewIndex] = useState("");
  const [count1, setcount1] = useState(0);
  const [socketDetails, setSocketDetails] = useState([]);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const timer = `${timeHr < 10 ? `0${timeHr}` : timeHr}:${timemin < 10 ? `0${timemin}` : timemin
    }:${timesec < 10 ? `0${timesec}` : timesec}`;

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [, projectId, projectName])
  );

  useEffect(() => {
    const b = timer.split(":"); // split it at the colons
    const result = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
    setvalveamount(result);
  }, [timer]);

  useEffect(() => {
    if (vNewIndex != "") {
      getValvesData(vNewIndex);
    }
  }, [valveamount]);

  let socket;

  useEffect(() => {
    const connectWebSocket = () => {
      socket = new WebSocket(baseUrlSocket);

      socket.onopen = () => {
        sendMessage();
      };

      socket.onmessage = (event) => {
        const message = event.data;
        setSocketDetails(JSON.parse(message));
        setrefreshflatlist(true);
        console.log('Received message:', message);
      };

      socket.onerror = (error) => {
        console.log('WebSocket error:', error);
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event.code, event.reason);
      };
    };

    connectWebSocket();

    setInterval(() => {
      sendMessage()
    }, 5000)

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [OmegaId, projectId]);

  const sendMessage = async () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      let jsonValue = await AsyncStorage.getItem("userData");
      let projValue = await AsyncStorage.getItem("projectData");

      if (projValue != null) {
        const data = projValue + "-" + OmegaId;
        socket.send(data);
      } else if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        const data = userDetailsresponse.project_selected + "-" + OmegaId;
        socket.send(data);
      }
    }
  };

  const downTimeAmount = () => {
    const interval = setInterval(() => {
      setvalveDetCount(valveDetails[selectedValveIndex]["leftover"] - 1)
    }, 1000);
    return () => clearInterval(interval);
  }
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proName = await AsyncStorage.getItem("projectName");
    setProjectName(JSON.parse(proName));
    let proCode = JSON.parse(proCodeId);
    let projValue = await AsyncStorage.getItem("projectData");
    getValveOmegaDetails(projValue);
    if (projValue != null) {
      setProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getValveOmegaDetails(userDetailsresponse.project_selected);
    }
  };

  const getValvesData = async (vIndex) => {
    var vIn = vIndex;

    setVNewIndex(vIn);
  };
  const getCounts = (count1) => {
    if (count1 == 1) {
      // downTimeAmount();
      startProgram();
    }
  }

  //start Program
  const startProgram = async () => {
    if (vNewIndex != "") {
      var dIndex = valveDetails.filter((item) => (item.index == vNewIndex))
      dIndex[0]["leftover"] = Number(valveamount);
      dIndex[0]["state"] = valveState;
      dIndex[0]["status"] = valveStatus;
      dIndex[0]["index"] = vNewIndex;
      dIndex[0]["index"] = vNewIndex;
      dIndex[0]["name"] = valveName;
      dIndex[0]["initialTime"] = valveamount;

      let newData = JSON.stringify(dIndex).slice(1, -1)
      let valveData = JSON.parse(newData);
      valveDetails[selectedValveIndex] = valveData;

      setValveDetails(valveDetails);
      setrefreshflatlist(true);
    }
    setIsLoading(true);
    try {
      var startProgram = JSON.stringify({
        omega_id: OmegaId,
        index: valveIndex,
        type: isMeasureType,
        amount: valveamount,
      });
      const startProgramResponse = await postData(
        startProgram,
        "reports/valveStart"
      );
      setIsLoading(false);
      var code = startProgramResponse.code;
      if (code == 1) {
      } else {
        setIsLoading(false);
        dIndex[0]["initialTime"] = 0
        //alert(startProgramResponse.message);
      }
    } catch (error) {
      dIndex[0]["initialTime"] = 0
      setIsLoading(false);
      // console.log(error);
    }
  };
  //stop Program
  const stopProgram = async (index) => {
    setIsLoading(true);
    try {
      var stopProgram = JSON.stringify({
        omega_id: OmegaId,
        index: index,
      });
      // console.log("stop program payload", stopProgram);
      const stopProgramResponse = await postData(
        stopProgram,
        "reports/valveStop"
      );
      setIsLoading(false);
      var code = stopProgramResponse.code;
      if (code == 1) {
        // console.log(" stop valve", stopProgramResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Get details when click on omega id
  const getValveOmegaDetails = async (proId) => {
    try {
      var OmegaDetails = JSON.stringify({
        omega_id: OmegaId,
        project_id: proId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "dashboards/dashboardGridView"
      );
      setIsLoadingSync(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        setIsOnline(OmegaDetailsResponse.data[0].online);
        setValveDetails(OmegaDetailsResponse.data[0].valves);
        setWaterMeterDetails(OmegaDetailsResponse.data[0].watermeter);
        setAnalogDetails(OmegaDetailsResponse.data[0].analogs);
        setOmegaDetailsGrid(OmegaDetailsResponse.data);
      } else {
        setIsLoadingSync(false);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  const getDetailsFromSocket = (index) => {
    var mainindex = index;
    var leftover = 0;
    var state = 0;
    var valveIndex = 0;
    var mainsIndex = "";
    var arr: number[] = []
    console.log("valveDetails", valveDetails);
    console.log("socketDetails", socketDetails);
    console.log("arr", arr);



    try {
      if (socketDetails[0].programs && socketDetails[0].programs.length > 0) {
        for (let j = 0; j < socketDetails[0].programs.length; j++) {
          if (socketDetails[0].programs[j].valve == valveDetails[mainindex].index) {
            console.log("socketDetails[0].programs[j]", j)
            mainsIndex = socketDetails[0].programs[j].index;
            valveIndex = socketDetails[0].programs[j].valve;
            leftover = Number(socketDetails[0].programs[j].leftover);
            state = socketDetails[0].programs[j].state;
            arr.push(Number(socketDetails[0].programs[j].leftover))
            break;
          }
        }
      }
    } catch (error) {
      console.log("socket disconnected121212", error);
    }

    const data = { "leftover": leftover, "state": state }

    return data;
  }

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;


  const renderValveItem = (item, index) => {
    const data = getDetailsFromSocket(index);
    var tempProgramArray = [];
    var tempValveArray = [];
    var tempLeft: number | any[] = []
    var leftover = data.leftover
    var state = data.state
    var flow = 0
    if (socketDetails.length != 0) {
      var tempsocketDetailsValve = socketDetails[0].valves
      if (tempsocketDetailsValve) {
        for (let i = 0; i < tempsocketDetailsValve.length; i++) {
          const element = tempsocketDetailsValve[i];
          if (element.inputNumber === item.inputNumber) {
            tempValveArray.push(element);
          }
        }
      }

      var tempsocketDetails = socketDetails[0].programs
      console.log("tempsocketDetails", tempsocketDetails);

      if (tempsocketDetails) {
        for (let i = 0; i < tempsocketDetails.length; i++) {
          const element = tempsocketDetails[i];
          if (element.valve === Number(item.inputNumber)) {
            flow = element.flow
            tempProgramArray.push(element);
            tempLeft.push(element.leftover)
          }
        }
      }
    }
    console.log("tempValveArray", tempValveArray);
    console.log("tempProgramArray", tempProgramArray);
    console.log("tempLeft", tempLeft);


    var initialTimeNew = 0
    if (tempProgramArray.length > 0) {
      initialTimeNew = (100 / tempProgramArray[0].perc) * tempProgramArray[0].leftover;
    }
    var alert = ''
    if (tempValveArray.length > 0) {
      if (tempValveArray[0].status == 'OPEN_WITH_ERROR_WITH_LOW_FLOW' || tempValveArray[0].status == 'CLOSE_WITH_ERROR_NO_PULSE' || tempValveArray[0].status == 'OPEN_WITH_ERROR_NO_PULSE') {
        alert = 'No pulse'
        console.log("inside if");
      } else if (tempValveArray[0].status == 'CLOSE_WITH_ERROR_HIGH_FLOW' || tempValveArray[0].status == 'OPEN_WITH_ERROR_HIGH_FLOW') {
        alert = 'High Flow'
        console.log("inside else if");
      } else if (tempValveArray[0].status == 'CLOSE_WITH_ERROR_LOW_FLOW' || tempValveArray[0].status == 'OPEN_WITH_ERROR_LOW_FLOW') {
        alert = 'High Flow'
        console.log("inside else if 2");

      } else {
        alert = ''
        console.log("inside else ");

      }
    }
    console.log("alert", alert);
    console.log("leftover", leftover);
    console.log("initialTimeNew", initialTimeNew);

    function secondsToHMS(totalSeconds: any) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      // Format the result
      const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // console.log("formattedTime", formattedTime);
      
      return formattedTime;
    }
    return (
      <View style={{}}>
        <View style={{ padding: 10 }}>
          <View style={{ alignSelf: 'center', marginBottom: 5 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{
                alignSelf: "center",
                marginLeft: 20,
                fontWeight: "500",
                fontSize: 18
              }}>
                {item.name}

                <View
                  style={{
                    width: "10%",
                    left: 5,
                    top: "0px",
                  }}
                >
                  <Menu>
                    <MenuOptions
                      optionsContainerStyle={{
                        borderWidth: 1,
                        zIndex: 5,
                        flex: 1,
                        width: "10%",
                        // width: useWindowDimensions().width,
                        position: "absolute",
                        marginTop: -92,
                        marginLeft: -150,
                        borderColor: "#7b757573",
                        height: 30
                      }}
                    >
                      {tempProgramArray.length == 0 && (
                        <MenuOption
                          onSelect={() => {
                            setSelectedValveIndex(index)
                            setValveIndex(item.index);
                            setValveName(item.name)
                            setvalveStatus(item.status);
                            setValveState(item.state)
                            getValvesData(item.index, item.name);
                            setValveIsVisible(true);
                            setvalveamount(0);
                          }}
                          text={"Start"}
                        />
                      )}
                      {tempProgramArray.length > 0 && (
                        <MenuOption
                          onSelect={() => {
                            stopProgram(item.index);
                          }}
                          text={"Stop"}
                        />
                      )}
                    </MenuOptions>
                    <MenuTrigger
                      text=""
                      customStyles={{
                        triggerWrapper: {
                          width: 30,

                        },
                      }}
                    >
                      <IconCross
                        color="#000"
                        name="dots-three-vertical"
                        size={15}
                      />
                    </MenuTrigger>
                  </Menu>
                </View>
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginLeft: '20%', }}>
              <Text style={{
                fontWeight: "500",
                fontSize: 30
              }}>
                {flow}
              </Text>
              <View style={{ marginLeft: 10, alignSelf: 'center' }}>
                <Text style={{
                  fontWeight: "500",
                  fontSize: 10,
                  color: 'grey'
                }}>
                  Flow
                </Text>
                <Text style={{
                  fontWeight: "500",
                  fontSize: 10,
                  color: 'grey'
                }}>
                  (m3/h)
                </Text>
              </View>
            </View>
          </View>
          <CountdownCircleTimer
            isPlaying={state === 3 ? false : true}
            // duration={item.leftover}
            // duration={item.initialTime !== null ? item.initialTime : 0}
            duration={initialTimeNew}
            // initialRemainingTime={leftover !== 0 ? item.leftover : leftover}
            // initialRemainingTime={leftover}
            // key={leftover}
            initialRemainingTime={tempProgramArray.length > 0 ? tempProgramArray[0].leftover : 0}
            key={tempProgramArray.length > 0 ? tempProgramArray[0].leftover : ''}
            colors={['#009df0', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
            rotation={'counterclockwise'}
            size={140}
          >
            {({ remainingTime }) => {
              <Text></Text>
              var hours = Math.floor(remainingTime / 3600).toString()
              var minutes = Math.floor((remainingTime % 3600) / 60).toString()
              var seconds = Math.floor((remainingTime % 60)).toString()
              hours = ('0' + hours).slice(-2)
              minutes = ('0' + minutes).slice(-2)
              seconds = ('0' + seconds).slice(-2)
              return (
                <View>
                  {/* <Text style={{
                    fontWeight: "500",
                    fontSize: 14,
                    width: 90
                  }}

                    numberOfLines={1}>
                    Descriptionssss
                    {/* item.description */}
                  <Text style={{
                    alignSelf: "center",
                    marginTop: 2,
                    fontWeight: "500",
                    fontSize: 15
                  }}>
                    {item.measurementype == 1 ?
                      // secondsToHMS(leftover)
                      (leftover / 1000) + ' m³'
                      :
                      new Date(leftover * 1000).toISOString().slice(11, 19)
                    }
                  </Text>
                  <View style={{ height: 25 }}>
                    {alert != '' &&
                      <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                        <AntDesign
                          color="red"
                          name="exclamationcircleo"
                          size={16}
                        />
                        <Text style={{
                          fontWeight: "500",
                          fontSize: 12,
                          color: 'red',
                          marginLeft: 5,
                        }}>
                          {alert}
                        </Text>
                      </View>
                    }
                  </View>
                </View>
              )
            }
            }


          </CountdownCircleTimer>

        </View>
      </View>
    );
  };

  const renderItemLeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var value = 0;
    var error = "";

    try {
      if (socketDetails[0].analogs && socketDetails[0].analogs.length > 0) {
        for (let j = 0; j < socketDetails[0].analogs.length; j++) {
          if (Number(socketDetails[0].analogs[j].index) === Number(analogDetails[mainindex].index)) {
            name = socketDetails[0].analogs[j].name;
            value = socketDetails[0].analogs[j].value;
            error = socketDetails[0].analogs[j].error;
            break;
          }
        }
      }
    } catch (error) {
      console.log("socket disconnected121212", error);
    }
    return (
      <View style={{ justifyContent: "space-between" }}>
        {isOnline == "1" ?
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{name !== "" ? name : ""}</Text>
              </Tooltip>
            </View>
            {error ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 0,
                  color: "red",
                  marginTop: 5,
                  fontWeight: "700",
                  marginRight: 20,
                  padding: 2,
                }}
              >
                {error}
              </Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  // padding: 2,
                  marginTop: 10,
                  marginRight: 20,
                }}
              > </Text>
            )}

            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 15,
                marginTop: 5,
                color:
                  item.level == "Open"
                    ? "#0fbf00"
                    : item.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: 30 }}>{value}</Text> {item.unitOfMeasure}
            </Text>
          </View>
          :
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {item.name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{item.name !== "" ? item.name.toLowerCase() : ""}</Text>
              </Tooltip>
            </View>
            {item.error ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 0,
                  color: "red",
                  marginTop: 5,
                  fontWeight: "700",
                  marginRight: 20,
                }}
              >
                {item.error}
              </Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  // padding: 2,
                  marginTop: 14,
                  marginRight: 20,
                }}
              > </Text>
            )}

            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 15,
                color:
                  item.level == "Open"
                    ? "#0fbf00"
                    : item.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: 30 }}>--</Text> {item.unitOfMeasure}
            </Text>
          </View>
        }
      </View>
    );
  };

  const renderWaterMtLeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var totalizer = 0;
    var status = 0;
    var error = "";
    var flow = 0;
    var inputNumber = 0;



    try {
      if (socketDetails[0].watermeter && socketDetails[0].watermeter.length > 0) {
        for (let j = 0; j < socketDetails[0].watermeter.length; j++) {
          if (Number(socketDetails[0].watermeter[j].index) === Number(waterMeterDetails[mainindex].index)) {
            name = socketDetails[0].watermeter[j].name;
            totalizer = socketDetails[0].watermeter[j].totalizer;
            status = socketDetails[0].watermeter[j].status;
            error = socketDetails[0].watermeter[j].error;
            flow = socketDetails[0].watermeter[j].flow;
            inputNumber = socketDetails[0].watermeter[j].inputNumber;
            break;
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }

    return (
      // <View style={{ justifyContent: "space-between", width: "100%" }}>
      <View style={{ justifyContent: "space-between" }}>
        {name != undefined && name != "" && isOnline == "1" && inputNumber < 3 && (
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{name}</Text>
              </Tooltip>
            </View>
            <View>
              {inputNumber < 3 ?
                <Text style={{ alignSelf: "flex-start" }}> {totalizer / 1000} m³</Text>
                :
                <Text style={{ alignSelf: "flex-start", marginTop: 15 }}>{""}</Text>
              }
            </View>
            {error != "" ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            )}
            {inputNumber < 3 &&
              <Text
                style={{
                  width: "100%",
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 15,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: 30 }}>{flow}</Text> m³/h
              </Text>
            }
            {inputNumber > 2 && socketDetails[0].digital_inputs[inputNumber] === 0 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5
                }}
              >Open
              </Text>
            }

            {inputNumber > 2 && socketDetails[0].digital_inputs[inputNumber] === 1 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5
                }}
              >Close
              </Text>
            }
          </View>
        )}
        {item.name != undefined && item.name != "" && isOnline == "0" &&
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {item.name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{item.name}</Text>
              </Tooltip>
            </View>
            <View>
              {item.index < 3 ?
                <Text style={{ alignSelf: "flex-start" }}> 0 m³</Text>
                :
                <Text style={{ alignSelf: "flex-start", marginTop: 15 }}>{""}</Text>
              }
            </View>
            {item.error != "" ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            )}
            {item.index < 3 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 15,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: 30 }}>--</Text> m³/h
              </Text>
            }

            {item.index > 2 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5,
                }}
              >--
              </Text>
            }
          </View>
        }
      </View>
    );
  };

  const renderItemRight = (item, index) => {
    var nextIrrigationTime = "";
    var lastIrrigationTime = "";
    var lastConnection = "";
    var lastIrrigationName = "";
    var isonline = false;
    try {
      if (socketDetails[0]) {
        nextIrrigationTime = socketDetails[0].nextIrrigationTime;
        lastIrrigationTime = socketDetails[0].lastIrrigationTime;
        lastConnection = socketDetails[0].lastConnection;
        isonline = socketDetails[0].isonline;
        lastIrrigationName = socketDetails[0].lastIrrigationName;
      }
    } catch (error) {
      console.log("error", error)
    }
    return (

      <View style={isMobile ? {
        width: 150,
        marginBottom: 10,
        padding: 10,
        borderRadius: 10,
        marginLeft: -60
      } : [styles.cardRight]}>
        <TouchableOpacity
          onPress={() => {
          }}
        >
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "#009df01f",
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, margin: 10 }}>
                <Image
                  style={{
                    height: 35,
                    width: 25,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/omega.png")}
                />
              </View>
              <View style={{ flex: 1, margin: 10 }}>

                <Text>{item.device_name}</Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Icon name="wifi" color={item.isonline == true ? "#228B22" : "#FF0000"} size={20} />
              </View>
            </View>

            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Next water supply" : "Next irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {nextIrrigationTime
                    ? nextIrrigationTime : "-"
                  }
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Last water supply" : "Last irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {lastIrrigationTime
                    ? 
                     lastIrrigationTime +` (${lastIrrigationName})` :"-"}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {
                  lastConnection ?  lastConnection :"-"
                  }
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      // </View>
    );
  };

  return (
    <View style={styles.MainContainer}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <Modal
        onRequestClose={() => setValveIsVisible(false)}
        transparent={true}
        visible={ValveIsVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setValveIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <Text style={{ alignSelf: "center", fontWeight: "700" }}>
              Set Amount
            </Text>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RadioButton
                uncheckedColor={"#707070"}
                color={"#707070"}
                value="Duration"
                status={isMeasureType == 2 ? "checked" : "unchecked"}
                onPress={() => setMeasureType(2)}
              />
              <Text style={{ fontSize: 12, color: "#707070" }}>Duration</Text>
              <RadioButton
                color={"#707070"}
                uncheckedColor={"#707070"}
                value="Quantity"
                status={isMeasureType == 1 ? "checked" : "unchecked"}
                onPress={() => setMeasureType(1)}
              />
              <Text style={{ fontSize: 12, color: "#707070" }}>Quantity</Text>

            </View>

            <View
              style={{ width: "90%", alignSelf: "center", marginBottom: 10 }}
            >
              {isMeasureType == 2 ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timeHr < 23 && settimeHr(timeHr + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timeHr < 10 ? `0${timeHr}` : timeHr}{" "}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timeHr > 0 && settimeHr(timeHr - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginHorizontal: 5 }}>:</Text>
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timemin < 59 && settimemin(timemin + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timemin < 10 ? `0${timemin}` : timemin}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timemin > 0 && settimemin(timemin - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginHorizontal: 5 }}>:</Text>
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timesec < 59 && settimesec(timesec + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timesec < 10 ? `0${timesec}` : timesec}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timemin > 0 && settimesec(timesec - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ) : (
                <TextInput
                  style={styles.input}
                  placeholder="Enter Amount"
                  value={valveamount}
                  autoFocus={true}
                  onChangeText={(text) => setvalveamount(text)}
                />
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginHorizontal: 20,
              }}
            >
              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  getCounts(1)
                  setValveIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Save</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  setValveIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <View style={{ flexDirection: "row", width: "100%", marginTop: "-1%" }}>
        <View style={{ flex: 1, marginHorizontal: 10 }}>
          <Text style={styles.lableText}>
            Monitor {">"}
            <Text onPress={() => navigation.navigate("GridView")}>
              Grid View
            </Text>
            {">"} {project_name} {">"} {OmegaName}
          </Text>
        </View>
      </View>
      {isLoadingSync ? (
        <ActivityIndicator
          color="#1DA1F2"
          visible={isLoadingSync}
          textContent={"Loading..."}
          size={100}
        />
      ) : (
        <View>
          <View
            style={{
              flexDirection: "row",
              marginTop: "0%",
              flexWrap: "wrap-reverse",
            }}
          >
            <MenuProvider
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {valveDetails != null ?
                (
                  valveDetails.length > 0 && (
                    valveDetails.map((item, index) => {
                      return renderValveItem(item, index);
                    })
                  )
                )
                :
                (
                  // valveDetails.length == 0 && (
                  <View style={{ justifyContent: "center" }}>
                    <Text
                      style={[
                        styles.emptyListStyle,
                        { alignSelf: "center", marginTop: 20 },
                      ]}
                    >
                      No Valve Data Found{" "}
                    </Text>
                  </View>
                  // )
                )
              }
            </MenuProvider>

            <View
              style={{
                flexDirection: "row",
                width: "25%",
              }}
            >
              {OmegaDetailsGrid.map((item, index) => {
                return renderItemRight(item, index);
              })}
            </View>
          </View>
          <View
            style={{
              marginTop: "2%",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <FlatList
                numColumns={1}
                style={{ width: "75%" }}
                contentContainerStyle={{
                  padding: 5,
                }}
                // horizontal
                data={analogDetails}
                renderItem={renderItemLeft}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: 'wrap'
              }}
            >
              <FlatList
                numColumns={3}
                style={{ width: "75%", }}
                contentContainerStyle={{
                  padding: 5,
                }}

                // horizontal
                data={waterMeterDetails}
                renderItem={renderWaterMtLeft}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    padding: 20,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
  },
  btnTextCancel: {
    color: "#747474",
    fontWeight: "600",
    textAlign: "center",
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    // marginLeft: 20,
    padding: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "30%",
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
  },

  cardLeft: {
    width: "15%",
    // flex: 0.5,

    marginVertical: 10,
    padding: 10,
    // flexDirection: 'row'
    borderRadius: 10,
  },
  cardRight: {
    width: "100%",
    // flex: 0.5,

    marginBottom: 10,
    padding: 10,
    // flexDirection: 'row'
    borderRadius: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
});
