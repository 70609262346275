import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Switch,
  Modal,
  Dimensions,
} from "react-native";
import { Text, View } from "../../../components/Themed";
import { jsonToCSV } from "react-native-csv";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import React, { useEffect, useState } from "react";
import Icon from "react-native-vector-icons/AntDesign";
import { useFocusEffect } from "@react-navigation/native";
import { postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
// import { Provider as PaperProvider } from "react-native-paper";
import DataTable from "react-data-table-component";

import IconEdit from "react-native-vector-icons/FontAwesome";
import { TextInput } from "react-native-paper";
import IconCross from "react-native-vector-icons/Entypo";

import IconEye from "react-native-vector-icons/Entypo";
import { color } from "react-native-elements/dist/helpers";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function ZoneProfile({ navigation }) {
  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  var [counter, setCounter] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [ZoneDataLength, setZoneDataLength] = useState(0);
  const [ZoneNameData, setZoneNameData] = useState([]);
  const [zoneListExport, setZoneListExport] = useState([]);
  const [projectId, setProjectId] = useState("");
  var idProject = useSelector((state) => state.idUpdating);

  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [isZoneNameDataValid, setZoneNameDataValid] = useState(false);
  const [isZoneNameDataEntered, setZoneNameDataEntered] = useState(false);
  const [sort, setsort] = useState(0);

  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  // Datatable subzone profile column and style

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          textTransform: "Capitalize",
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.zone_name.toLowerCase();
    const b = rowB.zone_name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      //cell: (row, index) => index + 1,

      // sortable: true,
      width: "100px",
    },

    {
      name: "Zone",
      selector: "zone_name",
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Code",
      selector: "zone_code",
      sortable: true,
    },
    {

      name: "Omega Name",
      //  selector: "devices_master.device_name" === null ? "" : "devices_master.device_name",
      // selector: "project_devices[]" == null ? "project_devices[0].devices_master.device_name" : null,
      selector:(row) => row.zone_devices.length > 0 ? row.zone_devices[0].devices_master === null ? "NA" : row.zone_devices[0].devices_master.device_name: 'NA',
      sortable: true,
    },,
    {
      name: "Action",
      // selector: "country",
      width: "100px",
      // sortable: true
      cell: (item) => (
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            style={{ marginRight: 10 }}
            onPress={() => {
              // console.log("item---edit", item);
              navigation.navigate("EditZoneProfile", { ZoneId: item.id });
            }}
          >
            <IconEdit
              name="pencil"
              size={13}
              color="#fff"
              style={{
                padding: 6,
                backgroundColor: "#08588E",
                borderRadius: 12,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              // console.log("item---VIEW", item);
              navigation.navigate("ViewZoneProfile", { ZoneId: item.id });
            }}
            style={{ marginRight: 10 }}
          >
            <IconEye
              name="eye"
              size={13}
              color="#fff"
              style={{
                padding: 6,
                backgroundColor: "#08588E",
                borderRadius: 12,
              }}
            />
          </TouchableOpacity>
        </View>
      ),
    },
    {
      name: "Status",
      width: "100px",
      cell: (item, index) => (
        <Switch
          // disabled={true}
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];
  const toggleSwitch = () => {
    var tempIndex = ZoneNameData.findIndex((item) => item.id === popid);

    if (ZoneNameData[tempIndex].active == 1) {
      ZoneNameData[tempIndex].active = 0;
      activeZone(popid, 0);
    } else {
      ZoneNameData[tempIndex].active = 1;
      activeZone(popid, 1);
    }
    setZoneNameData(ZoneNameData);
  };
  //Pagination
  const optionsPerPage = [10, 25, 50, 100];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, ZoneDataLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();

      searchFilterFunction("");
    }, [])
  );
  useEffect(() => {
    // console.log("is press Zone ");
    getAdminData();
  }, [idProject]);
  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");
    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in subzone create", projValue);
      setProjectId(projValue);
      getAllZoneProfileData([], projValue);
      getZoneListForExport(projValue)
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setProjectId(userDetailsresponse.project_selected);
      // console.log("json value id", userDetailsresponse.project_selected);
      getAllZoneProfileData([], userDetailsresponse.project_selected);
      getZoneListForExport(userDetailsresponse.project_selected)
    }
  };

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = ZoneNameData.filter(function (item) {
        const itemData = item.zone_name
          ? item.zone_name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(ZoneNameData);
      setSearch(text);
      setPage(0);
    }
  };
  //isActive State
  const activeZone = async (cityId, isActive) => {
    setIsLoading(true);
    try {
      var ZoneData = JSON.stringify({
        id: cityId,
        module: "zone_master",
        data: {
          active: isActive,
        },
      });
      const activeZoneResponse = await postData(
        ZoneData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeZoneResponse.code;
      if (code == 1) {
        // //alert(activeZoneResponse.message);
        setIsVisible(false);
        getAllZoneProfileData([], projectId);
        getZoneListForExport(projectId)
        // console.log("Zone status changed", activeZoneResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeZoneResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const exportCsvZoneData = async () => {
    setIsLoading(true);
    try {
      var getZoneData = JSON.stringify({
        module: "zone_master",
      });
      const ZoneNameResponse = await postData(
        getZoneData,
        "masters/exportFile"
      );
      setIsLoading(false);
      var code = ZoneNameResponse.code;
      if (code == 1) {
        // console.log("ZoneNameResponse list ====>", ZoneNameResponse.data);

        // setZoneDataLength(ZoneNameResponse.data.length);
        setZoneNameData(ZoneNameResponse.data);
        // setFilteredDataSource(ZoneNameResponse.data)
        // const results = jsonToCSV(ZoneNameResponse.data);
        // // console.log("csv==", results);
      } else {
        setIsLoading(false);
        //alert(ZoneNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Get all Zone list Api
  const getAllZoneProfileData = async (sortdata: never[], pid) => {
    // console.log("pid", pid);
    setIsLoading(true);
    if (sortdata.length > 0) {
      setZoneDataLength(sortdata.length);
      setZoneNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var getZoneData = JSON.stringify({
          condition: {
            project_id: pid,
          },
          module: "zone_master",
          relation: [
            {
              module: "zone_devices",
              subModule: "devices_master",
            },
          ],
        });
        const ZoneNameResponse = await postData(
          getZoneData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = ZoneNameResponse.code;
        if (code == 1) {
          // console.log("ZoneNameResponse list====>", ZoneNameResponse.data);
          setZoneDataLength(ZoneNameResponse.data.length);
          setZoneNameData(ZoneNameResponse.data);
          setFilteredDataSource(ZoneNameResponse.data);
          const results = jsonToCSV(ZoneNameResponse.data);
          // console.log("csv==", results);
        } else {
          setIsLoading(false);
          //alert(ZoneNameResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.zone_name.localeCompare(b.zone_name)
        : b.zone_name.localeCompare(item.zone_name)
    );
    getAllZoneProfileData(sortData);
    setFilteredDataSource(sortData);
  };

  const getZoneListForExport = async (pid) => {
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        modelName: "zone_master",
        project_id: pid,
      });
      const zoneDataResponse = await postData(
        stateData,
        "masters/dependentListExport"
      );
      setIsLoading(false);
      var code = zoneDataResponse.code;
      console.log('zoneDataResponse==>', zoneDataResponse);
      if (code == 1) {
        setZoneListExport(zoneDataResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const downloadExcelReport = async () => {
    const filteredData = zoneListExport.map(({ zone_name, zone_code, omega, active }, index) => ({
      'Sr No': index + 1,
      'Zone': zone_name,
      'Code': zone_code,
      'Omega Name': omega ? omega : 'NA',
      'Active': active == 1 ? 'Active' : 'InActive',
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'zone.xlsx');
  }

  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;


  return (
    <View style={styles.container}>
      <View style={isMobile ? { width: "100%", marginTop: 270 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={isMobile ? { margin: 10, marginTop:-45, height:0,flex:1} : { flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Profile {">"} Zone/Group</Text>
        </View>
        <Modal
          visible={editpopup}
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => {
            setEditPopup(false);
          }}
        >
          <View style={isMobile ? {
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 5,
            alignSelf: "center",
            borderWidth: 1,
            // justifyContent: "center",
            height: "25%",
            margin: "auto",
            padding: 10,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "60%",
          } : [styles.contanerSwitch]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setEditPopup(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 0 }}
              />
            </TouchableOpacity>

            <Text style={[styles.tableHeader, { marginBottom: 20 }]}>
              Do You Want Update Status
            </Text>
            <View
              style={{
                flexDirection: "row",
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false), toggleSwitch();
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setEditPopup(false);
                }}
                style={styles.saveBtn}
              >
                <Text style={{ color: "white" }}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignItems: "center", flex: 2 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={isMobile ? {
              height: 35,
              borderWidth: 2,
              paddingLeft: 10,
              borderColor: "#319ef0",
              backgroundColor: "#FFFFFF",
              width: "50%",
              borderRadius: 5,
              color: "#000",
              fontWeight: "400",
            } : styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search Zone"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={isMobile ? {
              alignItems: "center",
              backgroundColor: "#006EB9",
              padding: 10,
              borderWidth: 1,
              borderColor: "#006EB9",
              width: "35%",
              // marginTop: 20,
            } : styles.addDevice}
            onPress={() => {
              navigation.navigate("CreateZoneProfile");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Create
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ flex: 1 }}>
          {/* <TouchableOpacity
            style={styles.addDevice}
            // onPress={() => isCreatable()}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Country
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>

      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-10%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9", width: '45%',marginRight:-10
          } : {
            alignItems: "center",
            backgroundColor: "#006EB9",
            padding: 10,
            borderWidth: 1,
            borderColor: "#006EB9", width: '10%'
          }}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>

      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
              borderRadius: 8,
              paddingVertical: 0,
              paddingHorizontal: 0,
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
              borderRadius: 8,
              // paddingVertical: 45,
              // paddingHorizontal: 100,
            }}
          >
            <View
              style={isMobile ? {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                width: "150%",
                alignSelf: "center",
              } : {
                borderWidth: 1,
                borderColor: "#e3e0e0",
                borderRadius: 2,
                width: "100%",
                alignSelf: "center",
              }}
            >
              <View>
                <DataTable
                  // title="Country"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  input: {
    borderColor: "gray",
    width: "60%",
    borderWidth: 1,
    borderRadius: 10,

    padding: 10,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
    color: "#000000",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  addDevice: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    // marginTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "70%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 10,
    opacity: 2,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "40%",
    color: "#000",
    fontWeight: "400",
  },
});
