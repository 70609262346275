import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput as TextInputRN,
  Modal,
  ScrollView,
  Button,
  Switch,
} from "react-native-web";
import { useFocusEffect } from "@react-navigation/native";

import { Dropdown } from "react-native-element-dropdown";
import { Text, View } from "../../../components/Themed";
import { RootStackScreenProps } from "../../../types"; // @ts-ignore
import Icon from "react-native-vector-icons/AntDesign";
import React, { useEffect, useState, useMemo, useRef } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import { postData } from "../../../networking/api";
// import { DataTable } from "react-native-paper";
import DataTable from "react-data-table-component";

import { Provider as PaperProvider } from "react-native-paper";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconEye from "react-native-vector-icons/Entypo";
import { TextInput } from "react-native-paper";
import SuccessPopup from "../../../components/SuccessPopup";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Dimensions } from "react-native";

export default function States(navigation) {
  var [counter, setCounter] = useState(1);

  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [StateNameData, setStateNameData] = useState([]);
  const [stateNameExportList, setStateNameExportList] = useState([]);
  const [CountryNameData, setCountryNameData] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [StateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  // var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  const [stateLength, setStateLength] = useState(0);
  const [isAdd, setisAdd] = useState(false);
  const [error, setError] = useState("");
  const [searchData, setSearch] = useState("");
  const [filterData, setFilteredDataSource] = useState([]);
  const [sort, setsort] = useState(0);
  const [editpopup, setEditPopup] = useState(false);
  const [popid, setpopid] = useState("");
  const [popindex, setpopindex] = useState("");

  const textStateNameData = useRef("");

  const dropdownRefcountry = useRef();

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const closeMenu = () => {
    dropdownRefcountry.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  const optionsPerPage = [3, 5, 7, 10];

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, stateLength);
  React.useEffect(() => {
    setPage(0);
    setpopindex("");
    setEditPopup(false);
    setpopid("");
  }, [itemsPerPage]);
  const isCreatable = () => {
    setIsVisible(true);
    setisAdd(true);
    setCountryValue("");
    setStateValue("");
  };

  //Datatable states columns and css

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",

          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          textTransform: "Capitalize",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.state.toLowerCase();
    const b = rowB.state.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const caseInsensitiveSortCountry = (rowA, rowB) => {
    const a = rowA.country_master.country.toLowerCase();
    const b = rowB.country_master.country.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.index,
      // //cell: (row, index) => index + 1,
      // sortable: true,
      width: "100px",
    },
    {
      name: "Country",
      selector: "country_master.country",
      sortable: true,
      sortFunction: caseInsensitiveSortCountry,
    },

    {
      name: "State",
      selector: "state",
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Action",
      // selector: "country",
      width: "100px",
      // sortable: true
      cell: (item) => (
        <TouchableOpacity
          onPress={() =>
            isAddable(
              item.id,
              item.country_id,
              item.country_master.country,
              item.state
            )
          }
        >
          <IconEdit
            name="pencil"
            size={13}
            color="#fff"
            style={{
              padding: 6,
              backgroundColor: "#08588E",
              borderRadius: 12,
            }}
          />
        </TouchableOpacity>
      ),
    },
    {
      name: "Status",
      width: "100px",
      cell: (item, index) => (
        <Switch
          trackColor={{ false: "#767577", true: "#1DA1F2" }}
          thumbColor={item.active == 1 ? "#fff" : "#f4f3f4"}
          activeThumbColor="#fff"
          onValueChange={() => {
            // console.log("toggle index", index);
            setEditPopup(true);
            setpopid(item.id);
            setpopindex(index);
          }}
          value={item.active == 1 ? true : false}
        />
      ),
    },
  ];

  const toggleSwitch = () => {
    var tempIndex = StateNameData.findIndex((item) => item.id === popid);

    if (StateNameData[tempIndex].active == 1) {
      StateNameData[tempIndex].active = 0;
      activeState(popid, 0);
    } else {
      StateNameData[tempIndex].active = 1;
      activeState(popid, 1);
    }
    setStateNameData(StateNameData);
  };
  const isAddable = (stateId, country_id, country_name, state_name) => {
    // console.log("country_name ", country_name);
    setIsVisible(true);
    setisAdd(false);

    //Set name to update
    // setCountryByName(country_id);
    setCountryValue(country_name);
    setStateValue(state_name);

    //set id to update
    setCountryValId(country_id);
    setStateValId(stateId);
  };
  useFocusEffect(
    React.useCallback(() => {
      getStates([]);
      getStatesListForExport([]);
      searchFilterFunction("");

      getCountry();
    }, [])
  );
  const renderItem = (item: any) => {
    // console.log("item---value", item.id, "--------", countryValue);
    return (
      <View>
        {isAdd == true && (
          <View style={styles.item}>
            <Text style={styles.textItem}>{item.country}</Text>
            {item.id === countryValId && (
              <Icon style={styles.icon} color="black" name="Safety" size={20} />
            )}
          </View>
        )}
        {isAdd == false && (
          <View style={styles.item}>
            <Text style={styles.textItem}>{item.country}</Text>
            {item.id === countryValId && (
              <Icon style={styles.icon} color="black" name="Safety" size={20} />
            )}
          </View>
        )}
      </View>
    );
  };

  const textToStateNameData = (text) => {
    textStateNameData.current = text.nativeEvent.text;
    // setStateValue(text.nativeEvent.text);
  };

  const showStateNameData = () => {
    return textStateNameData.current;
  };
  const searchFilterFunction = (text) => {
    // // console.log("itemDataitemData",StateNameData);
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const newData = StateNameData.filter(function (item) {
        const itemData = item.state
          ? item.state.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // console.log("new data search---->", newData);
      setFilteredDataSource(newData);
      setSearch(text);
      setPage(0);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(StateNameData);
      setSearch(text);
      setPage(0);
    }
  };
  const CheckEnteredData = async () => {
    var StateNameDatas =
      showStateNameData() !== "" ? showStateNameData() : StateValue;
    //setUserType(userTypes);
    if (StateNameDatas == "" && countryValue == "") {
      // //alert("pls enter");
      setError("Please enter State Name");
      // console.log("empty");
      // setIsVisible(false);
    } else if (alpha_pattern.test(StateNameDatas)) {
      {
        isAdd == true && createStates(StateNameDatas);
        setIsVisible(false);
      }

      {
        isAdd == false && updateStates(StateNameDatas);
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      setError("Please enter Character Only.");
      // //alert("only character:", StateNameDatas);
      // setIsVisible(false);
    }

    // navigation.replace('DrawerNavigation');
  };

  //Country Add Api
  const createStates = async (StateNames) => {
    // console.log("StateNameDataDatas", StateNames);
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        module: "state_master",

        data: {
          country_id: countryValId,
          state: StateNames,
          active: 1,
        },
      });
      // console.log("state payload", stateData);
      const StateNameDataResponse = await postData(
        stateData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = StateNameDataResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getStates([]);
        getStatesListForExport([]);
        setStateValue("");
        textStateNameData.current = "";
        // console.log("Added states", StateNameDataResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(StateNameDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Update State API
  const updateStates = async (StateNames) => {
    // console.log("updates states---->", countryValId);
    // console.log("stateNames states", StateNames);
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        id: stateValId,
        module: "state_master",
        data: {
          country_id: countryValId,
          state: StateNames,
          active: 1,
        },
      });
      const StateNameDataResponse = await postData(
        stateData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = StateNameDataResponse.code;
      if (code == 1) {
        setPopupMessage("Record Updated Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
        getStates([]);
        getStatesListForExport([]);
        setStateValue("");
        textStateNameData.current = "";
        // console.log("Updated states", StateNameDataResponse.data);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(StateNameDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          active: 1,
        },
        relation: [],
      });
      const CountryNameDataResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = CountryNameDataResponse.code;
      if (code == 1) {
        // console.log("list====>", CountryNameDataResponse.data);
        // setCountryLength(CountryNameDataResponse.data.length);
        // setCountryValue("");
        setCountryNameData(CountryNameDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(CountryNameDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // State Get Api
  const getStates = async (sortdata: never[]) => {
    setIsLoading(true);
    if (sortdata.length > 0) {
      setStateLength(sortdata.length);
      setStateNameData(sortdata);
      setFilteredDataSource(sortdata);
    } else {
      try {
        var stateData = JSON.stringify({
          module: "state_master",
          relation: [
            {
              module: "country_master",
            },
          ],
          // "relation": []
        });
        const StateNameDataResponse = await postData(
          stateData,
          "masters/getMasterList"
        );
        setIsLoading(false);
        var code = StateNameDataResponse.code;
        if (code == 1) {
          // console.log("state list====>", StateNameDataResponse.data);

          setStateLength(StateNameDataResponse.data.length);
          setStateNameData(StateNameDataResponse.data);
          setFilteredDataSource(StateNameDataResponse.data);
        } else {
          setIsLoading(false);
          //alert(StateNameDataResponse.message);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    }
  };

  const getStatesListForExport = async (sortdata: never[]) => {
    setIsLoading(true);
    try {
      var stateData = JSON.stringify({
        modelName: "state_master"
      });
      const StateNameDataResponse = await postData(
        stateData,
        "masters/dependentListExport"
      );
      setIsLoading(false);
      var code = StateNameDataResponse.code;
      console.log('StateNameDataResponse==>', StateNameDataResponse);
      if (code == 1) {
        setStateNameExportList(StateNameDataResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  //isActive State
  const activeState = async (stateId, isActive) => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        id: stateId,
        module: "state_master",
        data: {
          active: isActive,
        },
      });
      const activeStateResponse = await postData(
        countryData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = activeStateResponse.code;
      if (code == 1) {
        // //alert(activeStateResponse.message);
        setIsVisible(false);
        getStates([]);
        getStatesListForExport([]);
        // console.log("status changed", activeStateResponse.data);
      } else {
        setIsLoading(false);
        // //alert(activeStateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  function StateModal() {
    // const [isVisible, setIsVisible] = useState(false);

    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "rgba(100,100,100, 0.5)",
            }}
          >
            <View style={[styles.containeralt]}>
              <TouchableOpacity
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  setIsVisible(false), setError("");
                }}
              >
                <IconCross
                  name="circle-with-cross"
                  size={20}
                  color="#000"
                  style={{ bottom: 5, top: 10 }}
                />
              </TouchableOpacity>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "600" }}>Country Name</Text>
                {isAdd == true && (
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryNameData}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder="Select Country"
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      // console.log("value===>", countryValue);
                    }}
                    renderItem={renderItem}
                  />
                )}
                {isAdd == false && (
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryNameData}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder="Select Country"
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      setCountryValue(item.country);
                      setCountryValId(item.id);
                      // console.log("country kept value===>", item);
                    }}
                    renderItem={renderItem}
                  />
                )}
                {/* {countryValue} */}
              </View>
              <View style={{ width: "100%", marginTop: 5 }}>
                <Text style={{ fontWeight: "600" }}>State Name</Text>
                {isAdd == true && (
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    //
                    autoFocus={true}
                    onChange={textToStateNameData}
                  />
                )}

                {isAdd == false && (
                  <TextInputRN
                    style={styles.input}
                    placeholder=""
                    value={StateValue}
                    key="Edit"
                    autoFocus
                    onChangeText={(text) => {
                      setStateValue(text);
                      // console.log("Kept state value:", StateValue);
                    }}
                  />
                )}
                <Text style={[styles.error_text]}>{error}</Text>
              </View>
              {isAdd == true && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Save</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => {
                      setIsVisible(false), setError("");
                    }}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}
              {isAdd == false && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                  }}
                >
                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={styles.btnText}>Update</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.saveBtn}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </Modal>
      </>
    );
  }

  const sortFun = () => {
    const sortData = filterData.sort((item, b) =>
      sort == 1
        ? item.country_master.country.localeCompare(b.country_master.country)
        : b.country_master.country.localeCompare(item.country_master.country)
    );
    getStates(sortData);
    setFilteredDataSource(sortData);
  };

  const downloadExcelReport = async () => {
    const filteredData = stateNameExportList.map(({ country, state, active }, index) => ({
      'Sr No': index + 1,
      'Country': country,
      'State': state,
      'Active': active == 1 ? 'Active': 'InActive',
    }));
    // const filteredData = stateNameExportList.map(({ createdAt, updatedAt, index, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelData, 'state_master.xlsx');
  }

  
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;
 
  return (
    <View style={styles.container}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <Modal
        onRequestClose={() => setIsVisible(false)}
        transparent
        visible={isVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } : [styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>
            <View style={{ width: "100%" }}>
              <Text style={{ fontWeight: "600" }}>Country Name</Text>
              {isAdd == true && (
                <Dropdown
                  ref={dropdownRefcountry}
                  style={styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={CountryNameData}
                  search
                  maxHeight={300}
                  labelField="country"
                  valueField="country"
                  placeholder="Select Country"
                  searchPlaceholder="Search..."
                  value={countryValue}
                  onChange={(item) => {
                    setCountryValue(item.country);
                    setCountryValId(item.id);
                    // console.log("value===>", countryValue);
                  }}
                  renderItem={renderItem}
                />
              )}
              {isAdd == false && (
                <Dropdown
                  ref={dropdownRefcountry}
                  style={styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={CountryNameData}
                  search
                  maxHeight={300}
                  labelField="country"
                  valueField="country"
                  placeholder="Select Country"
                  searchPlaceholder="Search..."
                  value={countryValue}
                  onChange={(item) => {
                    setCountryValue(item.country);
                    setCountryValId(item.id);
                    // console.log("country kept value===>", item);
                  }}
                  renderItem={renderItem}
                />
              )}
              {/* {countryValue} */}
            </View>
            <View style={{ width: "100%", marginTop: 5 }}>
              <Text style={{ fontWeight: "600" }}>State Name</Text>
              {isAdd == true && (
                <TextInputRN
                  style={styles.input}
                  placeholder=""
                  //
                  autoFocus={true}
                  onChange={textToStateNameData}
                />
              )}

              {isAdd == false && (
                <TextInputRN
                  style={styles.input}
                  placeholder=""
                  value={StateValue}
                  key="Edit"
                  autoFocus
                  onChangeText={(text) => {
                    setStateValue(text);
                    // console.log("Kept state value:", StateValue);
                  }}
                />
              )}
              <Text style={[styles.error_text]}>{error}</Text>
            </View>
            {isAdd == true && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => {
                    setIsVisible(false), setError("");
                  }}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
            {isAdd == false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginHorizontal: 20,
                }}
              >
                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => CheckEnteredData()}
                >
                  <Text style={styles.btnText}>Update</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.saveBtn}
                  onPress={() => setIsVisible(false)}
                >
                  <Text style={styles.btnText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </Modal>
      <Modal
        visible={editpopup}
        animationType={"slide"}
        transparent={true}
        onRequestClose={() => {
          setEditPopup(false);
        }}
      >
        <View style={isMobile ? {
            // alignItems: "center",
            backgroundColor: "white",
            borderColor: "#eee",
            borderRadius: 10,
            borderWidth: 1,
            // justifyContent: "center",
            height: "auto",
            margin: "auto",
            padding: 30,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            // marginRight: 10,
            // marginRight: 1,
            width: "80%",
          } :[styles.contanerSwitch]}>
          <TouchableOpacity
            style={{ alignSelf: "flex-end" }}
            onPress={() => setEditPopup(false)}
          >
            <IconCross
              name="circle-with-cross"
              size={20}
              color="#000"
              style={{ bottom: 5, top: 0 }}
            />
          </TouchableOpacity>

          <Text style={[styles.tableHead, { marginBottom: 20 }]}>
            Do You Want Update Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false), toggleSwitch();
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEditPopup(false);
              }}
              style={styles.saveBtn}
            >
              <Text style={{ color: "white" }}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      {/* <Header /> */}
      {/* <Text style={styles.title}>This screen is Profile screeen.</Text> */}
      <View style={isMobile ? { width: "100%", marginTop: 180 } : { flexDirection: "row", width: "100%", marginTop: "10%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>Master {">"} State Master</Text>
        </View>
        <View style={isMobile ? { alignItems: "center", marginTop: 20  , marginLeft:'-35%', width:"170%", flex:2 } : { alignSelf: "center", alignItems: "center", flex: 2 }}>
          {/* <TouchableOpacity onPress={() => exportCsvZoneData()}><Text>Download CSV</Text></TouchableOpacity> */}
          <TextInput
            style={styles.textInputStyle}
            onChangeText={(text) => searchFilterFunction(text)}
            value={searchData}
            underlineColorAndroid="transparent"
            underlineColor={"white"}
            placeholder="Search State"
            theme={{
              colors: {
                text: "black",
                primary: "white",
                background: "transparent",
              },
            }}
            left={
              <TextInput.Icon
                name={() => <IconEdit name="search" size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              />
            }
          />
        </View>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => {
              isCreatable(), setError("");
            }}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add State
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={isMobile ? { width: '100%', alignItems: "flex-end", marginTop:"-10%", marginLeft:"-120%" } : { width: '100%', alignItems: "flex-end" }}>
        <TouchableOpacity
          style={isMobile ? [styles.addCountry, { width: '45%',marginRight:-10 }] : [styles.addCountry, { width: '10%' }]}
          onPress={() => {
            downloadExcelReport();
          }}
        >
          <Text style={styles.btnText}>
            <Icon name="exclefile1" size={15} color="#fff" /> Download
          </Text>
        </TouchableOpacity>
      </View>

      {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
      <View style={isMobile ? [styles.card, { flexGrow: 1, marginTop:50, paddingBottom:10 }] : [styles.card, { flexGrow: 1 }]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "white",
            },
          ]}
        >
          <View
            style={{
              width: "100%",
              // flex: 1,
              backgroundColor: "#F6F9F9",
            }}
          >
            <View
             style={isMobile ? {
              borderWidth: 1,
              borderColor: "#e3e0e0",
              width: "220%",
              alignSelf: "center",
            }: {
              borderWidth: 1,
              borderColor: "#e3e0e0",
            }}
            >
              <View>
                <DataTable
                  // title="State"
                  columns={columns}
                  data={filterData}
                  customStyles={customStyles}
                  pagination
                />
              </View>
              {/* prvider datatable code here */}

              {/* ended */}
            </View>
          </View>
          {/* </ScrollView> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 200,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "auto",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  contanerSwitch: {
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 5,
    alignSelf: "center",
    borderWidth: 1,
    // justifyContent: "center",
    height: "25%",
    margin: "auto",
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "30%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,

    padding: 10,
    marginTop: 6,
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "40%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  card: {
    width: "50%",
    flex: 1,
    marginVertical: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  textInputStyle: {
    height: 35,
    borderWidth: 2,
    paddingLeft: 20,
    borderRadius: 5,
    borderColor: "#319ef0",
    backgroundColor: "#FFFFFF",
    width: "35%",
    fontWeight: "400",
  },
  tableHead: {
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "700",
    color: "#000000",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",

    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  tableHeader: {
    color: "#000",
    fontSize: 14,
    /* FontFamily: 'Muli' ,*/
    fontWeight: "400",
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
    // fontSize: 21,
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },

  // Error message css
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
});
